import { GET_DUMMY_CATEGORY_ALLPARENTS, GET_CATEGORY_PRODUCT, GET_CATEGORY, GET_PARTICULAR_CATEGORY } from "../action.type";

const initialState={
    dummyCategoryAllParent: null,
    productCategory: [],
    category: [],
    partiCularCategory: {}
}                                 

export const dummyCategoryReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_DUMMY_CATEGORY_ALLPARENTS:
        return {
            ...state,
            dummyCategoryAllParent: action.payload,
        };
      case GET_CATEGORY_PRODUCT:
        return {
            ...state,
            productCategory: action.payload,
        };
      case GET_CATEGORY:
        return {
            ...state,
            category: action.payload,
        };
      case GET_PARTICULAR_CATEGORY:
        return {
            ...state,
            partiCularCategory: action.payload,
        };
      
      default:
        return state;
    }
  }
