import {
    Trash,
    X
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { addIPs, getRestrictedIP, removeIP } from "../../Redux/Action/restrictedIP.action";
import { ToastContainer } from "react-toastify";

const RestrictedIP = () => {
    const dispatch = useDispatch();
    const { getIp } = useSelector((state) => state.restrictedIPReducer);
    const [restrictedIP, setRestrictedIP] = useState([]);
    const [ip, setIP] = useState([]);
    const [ipArray, setIPArray] = useState([]);
    const [ipCount, setIPCount] = useState(0);

    // save Group Values In Array
    const SaveIPValue = (e) => {
        e.preventDefault();
        if (ip?.length > 0) {
            if (ip?.includes(',')) {
                let TagSeperator = ip.split(',');
                let finalArray = [...ipArray]; // Use a separate variable to store the final array
                TagSeperator?.filter(tag => {
                    if (tag && tag.length >= 3) {
                        const searchRegex = new RegExp(`^${tag}$`, "i");
                        let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
                        if (!exists) {
                            finalArray.push(tag);
                        }
                    }
                });
                setIPArray(finalArray);
            } else {
                if (ip.length >= 3) {
                    const searchRegex = new RegExp(`^${ip}$`, "i");
                    const exists = ipArray.some((tag) => searchRegex.test(tag));
                    if (!exists) {
                        setIPArray((prev) => [...prev, ip]);
                    }
                }
            }
            setIP(''); // Clear the input after saving
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            ipArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setIPArray([...ipArray]);
    };

    // Get Module Information
    const GetRestrictedIP = async () => {
        try {
            dispatch(getRestrictedIP());
        } catch (error) {
            console.log(error);
        }
    };

    const IPSave = async () => {
        try {
            dispatch(addIPs(ipArray));
            setIPArray([]);
        } catch (error) {
            console.log(error)
        }
    }

    const IPRemove = async (ip) => {
        if (window.confirm(`Remove "${ip}" IP?`)) {
            try {
                dispatch(removeIP(ip));
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        GetRestrictedIP();
    }, []);

    useEffect(() => {
        if(getIp) {
            setRestrictedIP(getIp?.data);
            setIPCount(getIp?.data?.length);
        }
    },[getIp])

    return (
        <>
            <div className="w-full pb-[64px]">
                <div>
                    <Breadcrumbs crumbs={[
                        { name: 'Dashboard', path: `/dashboard` },
                        { name: 'Restricted IP', path: `/restricted-ip` },
                    ]} />
                    <h1 className='text-xl md:text-2xl mb-4 font-semibold text-primary'>Restricted IP</h1>
                </div>
                <div>
                    <form className="">
                        <label className='flex items-center justify-between' htmlFor='IP'>
                            IP
                            <h1 className='text-gray-500 font-medium text-xs'>
                                Use <b className='text-black'>Enter</b> key to comma separate the ip
                            </h1>
                        </label>
                        <div className="flex">
                            <textarea
                                className='block w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='ip'
                                name='ip'
                                value={ip}
                                onChange={(e) => setIP(e.target.value)}
                                onKeyDown={(e) => (e.key === 'Enter' ? SaveIPValue(e) : '')}
                                placeholder='e.g. admin, login'
                            />
                            <div className='flex flex-wrap items-center mt-2'>
                            </div>
                        </div>
                        <small className="flex items-center justify-between">NOTE: Minimum 3 characters acceptable for each ip.</small>

                        {ipArray.length > 0 &&
                            <>
                                {ipArray.map((elem, index) => {
                                    if (elem !== "") {
                                        return (
                                            <span
                                                key={index}
                                                className='bg-[#f1f1f1] inline-flex items-center me-2 mb-2 mt-2 min-w-[50px] text-black text-xs text-center px-3 py-1.5 rounded-[10px]'
                                            >
                                                {elem}
                                                <X
                                                    className='text-black cursor-pointer h-[14px] w-[14px] ms-2'
                                                    onClick={() => RemoveTag(index)}
                                                />
                                            </span>
                                        );
                                    }
                                })}
                            </>
                        }
                    </form>
                    {ipArray.length > 0 &&
                        <button
                            type="button"
                            className="btn px-3 py-1 text-white rounded"
                            onClick={IPSave}
                        >
                            Submit
                        </button>
                    }
                </div>
                <div className="flex space-x-4 mt-5">
                    <div className="w-full space-y-4">
                        <div className="w-full overflow-x-auto">
                            <table className="w-full text-left border border-slate-300">
                                <thead className="h-[45px]">
                                    <tr>
                                        <th className="flex items-center space-x-1 cursor-pointer h-[45px] min-w-[300px] px-4">
                                            <span>IP</span> <span className="text-base">({ipCount})</span>
                                        </th>
                                        <th className="cursor-pointer min-w-[100px] text-right pe-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        restrictedIP?.length ? restrictedIP?.map((elem, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="bg-white w-full cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600"
                                                >
                                                    <td className="p-3"> {elem} </td>
                                                    <td className="space-x-3 text-right pe-3">
                                                        <button onClick={() => IPRemove(elem)}>
                                                            <Trash className="w-[18px] h-[18px] text-red-500" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <p className="text-gray-600 p-3">No data found</p>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default RestrictedIP;
