import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {viewGuide } from "../../Redux/Action/guide.action";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

const ViewGuide = () => {
  const navigate = useNavigate()
  const params = useParams();
  const dispatch = useDispatch();
  const {getPerticularExtensionData} = useSelector((state)=>state.guidesReducer)
  useEffect(() => {
    dispatch(viewGuide(params.guide_id));
    
}, []);

  return (
    <div className="w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <h1 className="text-3xl font-medium text-center text-primary">
          View Guide
        </h1>
        <button
          className="border py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-800 text-white"
          type="submit"
          onClick={() => navigate("/guide")}
        >
          My Guide
        </button>
      </div>

      <div className="h-full w-full">
        <div className="bg-white shadow-sm rounded-[4px]">
          <div className="text-gray-700  flex items-start justify-start">
            <div className="grid md:grid-cols-1 w-full py-5 text-sm">
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Title :</div>
                <div className="px-4 py-2">
                  {getPerticularExtensionData?.title}
                </div>
              </div>
              <div className="flex mb-2">
              {getPerticularExtensionData?.category?.category_name?<>
                <div className="px-4 py-2 font-semibold">Category :</div>
                <div className="px-4 py-2">
                 
                {getPerticularExtensionData?.category?.category_name}
                 
                </div>
                 </>:<></>}
              </div>
              <div className="flex mb-2">
              {getPerticularExtensionData?.extension?.extension_name?<>
                <div className="px-4 py-2 font-semibold">extension :</div>
                <div className="px-4 py-2">{getPerticularExtensionData?.extension?.extension_name}</div>
              </>:<></>}
              </div>         
              <div></div>
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Description:</div>
                <div className="px-4 py-2" dangerouslySetInnerHTML={{ __html: getPerticularExtensionData?.description}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewGuide;
