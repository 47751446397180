import { GET_RESTRITED_DOMAIN } from "../action.type";

const initialState={
    getDomain: [],
}                                 

export const restrictedDomainReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_RESTRITED_DOMAIN:
        return {
            ...state,
            getDomain: action.payload,
        };
      
      default:
        return state;
    }
  }
