import { GET_BLOG_CATEGORYS, GET_DYNAMIC_PAGES, GET_JOBS, GET_PARTICULAR_BLOG, GET_PARTICULAR_BLOG_CATEGORY, GET_PARTICULAR_DYNAMIC_PAGE, GET_PARTICULAR_JOB, GET_PARTICULAR_THEME, GET_PARTICULAR_TOOL, GET_THEME, GET_TOOLS, GET_WEBSITE_BLOG } from "../action.type";

const initialState = {
  seBlogs: [],
  blogCategorys: [],
  perticularBlog: {},
  perticularBlogCategory: {},
  jobs: [],
  perticularJob: {},
  tools: [],
  particularTool: {},
  themes: [],
  particularTheme: {},
  dynamicPages: [],
  particularPage: {},
}

export const websiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEBSITE_BLOG:
      return {
        ...state,
        seBlogs: action.payload,
      };
    case GET_BLOG_CATEGORYS:
      return {
        ...state,
        blogCategorys: action.payload,
      };
    case GET_PARTICULAR_BLOG:
      return {
        ...state,
        perticularBlog: action.payload,
      };
    case GET_PARTICULAR_BLOG_CATEGORY:
      return {
        ...state,
        perticularBlogCategory: action.payload,
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload,
      };
    case GET_PARTICULAR_JOB:
      return {
        ...state,
        perticularJob: action.payload,
      };
    case GET_TOOLS:
      return {
        ...state,
        tools: action.payload,
      };
    case GET_PARTICULAR_TOOL:
      return {
        ...state,
        particularTool: action.payload,
      };
    case GET_THEME:
      return {
        ...state,
        themes: action.payload,
      };
    case GET_PARTICULAR_THEME:
      return {
        ...state,
        particularTheme: action.payload,
      };
    case GET_DYNAMIC_PAGES:
      return {
        ...state,
        dynamicPages: action.payload,
      };
    case GET_PARTICULAR_DYNAMIC_PAGE:
      return {
        ...state,
        particularPage: action.payload,
      };

    default:
      return state;
  }
}
