import {
    Backdrop,
    CircularProgress,
    Dialog,
    Popover,
    Tooltip
} from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, { useRef, useState } from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../API";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import ImageUploader from "../../../controllers/ImageUploader";
import module, { formatCode } from "../../../controllers/QuillToolbar";
import TextEditor from "../../../controllers/TextEditor";
import { Transition } from "../../../controllers/Transition";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import { useDispatch } from "react-redux";
import { addTool } from "../../../Redux/Action/website.action";

function AddWebsiteTool() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [warn, setwarn] = useState(false);
    const [enableSlug, setEnableSlug] = useState(false);
    const [description, setDescription] = useState("");
    const [secondDescription, setSecondDescription] = useState("");
    const [title, setTitle] = useState("");
    const [shortDescription, setShortDescription] = useState('')
    const [meta, setMeta] = useState({ meta_title: "", meta_description: "" });
    const { meta_description, meta_title } = meta;
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();

    const [generateAiPopup, setGenareteAiPopup] = useState(false);
    const [gerenateAiData, setGenerateDataAi] = useState({
        features: "",
    });
    const [AiLoader, setAiloader] = useState(false);
    const [generateDescriptionAiPopup, setGenareteDescriptionAiPopup] =
        useState(false);

    const handleChangeAiData = (e) => {
        setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
    };

    const handleSubmitAiData = (e) => {
        e.preventDefault();
        generateDesc(gerenateAiData);
        setGenareteAiPopup(false);
        setGenerateDataAi({
            features: "",
        });
    };
    const generateDesc = async () => {
        setAiloader(true);
        try {
            let ApiCall = await API({ url: "/admin/ai/keypoint_generate", method: 'post', data: {
                name: gerenateAiData?.features,
            }});
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                setDescription(ApiCall.data.data);
                setGenareteDescriptionAiPopup(true);
            }
        } catch (error) {
            setAiloader(false);
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
        setAiloader(false);
    };


    // image upload logic
    const [image, setimage] = useState();
    const handleSetImage = (value) => {
        setimage(value);
    };
    const [slugField, setSlugField] = useState(false);
    const [slugName, setSlugName] = useState();
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };
    const [err, setErr] = useState(false);
    // add Tool
    const HandleSubmit = async (e) => {
        e.preventDefault();
        if (title === "" || !image?.length) {
            setErr(true);
        } else {
            try {
                const body = {
                    title: title,
                    image: image,
                    description: secondDescription,
                    tool_slug_name: slugName,
                    short_description: shortDescription,
                    ...meta,
                }
                dispatch(addTool(body));
            } catch (error) {
                console.log(error);
            }
        }
    };
    const [desErr, setDesErr] = useState(false);
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const handleEditorData = (value) => {
        setSecondDescription(value)
    }
    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setSecondDescription(value);
    };

    const [html, setHtml] = useState({ htmlPP: false, code: "" });
    const { htmlPP, code } = html;
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML;
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false });
        setSecondDescription(code);
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            {AiLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={AiLoader}
                    className="flex flex-col"
                >
                    <CircularProgress color="inherit" />
                    <p>AI is generating your content.Please wait...</p>
                </Backdrop>
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Add new tool
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto"
                    >
                        <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                            <div className="w-full">
                                <label
                                    htmlFor="title"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Tool Title
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                    {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                </label>
                                <input
                                    className={
                                        (err && title?.length === 0) || warn
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                            : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                    }
                                    type="text"
                                    name="title"
                                    // maxLength={60}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setwarn(false);
                                    }}
                                    placeholder="e.g. Tool Title"
                                    onBlur={() => handleSlug(title)}
                                />
                                {(err && title?.length === 0) || warn ? (
                                    <p className="text-red-500 text-xs">
                                        Please enter Tool title
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {slugField && (
                                <div className="w-full">
                                    <label htmlFor="category_name">
                                        tool Slug name
                                    </label>
                                    <div
                                        className={`flex items-center justify-between w-full border ${err && enableSlug
                                            ? "border-red-500"
                                            : "border-gray-300"
                                            } rounded-[4px]`}
                                    >
                                        <input
                                            className="w-full  focus:outline-none"
                                            type="text"
                                            name="tool_slug_name"
                                            value={slugName}
                                            disabled={!enableSlug}
                                            onChange={(e) =>
                                                setSlugName(e.target.value)
                                            }
                                        />
                                        {enableSlug ? (
                                            <Check
                                                onClick={async () => {
                                                    setSlugName(
                                                        await GenerateSlugName(
                                                            slugName
                                                        )
                                                    );
                                                    setEnableSlug(!enableSlug);
                                                    setErr(false);
                                                }}
                                                className="text-green-500 p-1 h-8 w-8"
                                            />
                                        ) : (
                                            <Edit
                                                onClick={() =>
                                                    setEnableSlug(!enableSlug)
                                                }
                                                className="text-blue-500 p-1 h-7 w-7"
                                            />
                                        )}
                                    </div>
                                    {err && enableSlug && (
                                        <p className="text-xsm text-red-500">
                                            Please save this changes
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="mb-4">
                                <label>Short description</label>
                                <textarea className={`w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                                    name="title"
                                    value={shortDescription}
                                    onChange={(e) => {
                                        setShortDescription(e.target.value);
                                    }}
                                    placeholder="e.g. Tool about your latest products or deals" />
                            </div>
                            <div className="mb-4">
                                <TextEditor label={'tool description'} handleEditorData={handleEditorData} />
                                <div className="flex items-center justify-between">
                                    {desErr ? (
                                        <p className="text-red-500 text-xs line-clamp-3">
                                            Please enter tool description
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    <p className="text-xs mt-0 text-gray-400">
                                        of 5000 words allowed
                                    </p>
                                </div>
                            </div>
                        </div>
                        <details
                            open
                            className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]"
                        >
                            <summary className="text-base flex items-center justify-between font-medium text-black">
                                <div>
                                    <span>Media</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Upload captivating image to make your
                                        tool stand out.
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <ImageUploader
                                alreadyImage={image}
                                handleSetImage={handleSetImage}
                                folder="tool"
                                format="image"
                            />
                        </details>
                        <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
                            <summary
                                htmlFor="Meta Tag Title"
                                className="text-base flex items-center justify-between font-medium text-black"
                            >
                                <div>
                                    <span>Search engine listing</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Add a title and description to see how
                                        this product might appear in a search
                                        engine listing
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag Title"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Title
                                    </label>
                                </div>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag Title"
                                    name="meta_title"
                                    value={meta_title}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta title"
                                />
                            </div>

                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag description"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Description
                                    </label>
                                </div>
                                <textarea
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag description"
                                    name="meta_description"
                                    value={meta_description}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta description"
                                />
                            </div>

                        </details>
                        <div className="flex items-center justify-between w-full">
                            <Link
                                to={`/site-data/tool`}
                                className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                            >
                                <ArrowLeft className="w-3 mr-1" />
                                Go to tools
                            </Link>
                            <button
                                className={
                                    secondDescription && title && image?.length
                                        ? "inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        : "font-medium text-sm text-white opacity-40 btn cursor-not-allowed"
                                }
                                type="button"
                                onClick={(e) => HandleSubmit(e)}
                                disabled={
                                    title === "" ||
                                    !image?.length ||
                                    secondDescription === ""
                                }
                            >
                                Add New Tool
                            </button>
                        </div>
                    </form>
                </div>

                <Dialog
                    open={generateAiPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setGenareteAiPopup(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="w-full sm:w-[600px] rounded max-h-full">
                        <div className="relative secondary-bg-color shadow dark:bg-gray-700 p-4">
                            <div className="flex items-center justify-between  border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600">
                                <h6 className="capitalize text-primary font-medium">
                                    Generate Tool description
                                </h6>
                                <button
                                    type="button"
                                    className="close-btn rounded-md text-primary hover:text-primary-color transition-all duration-200 border-none"
                                    onClick={() => {
                                        setGenareteAiPopup(false);
                                    }}
                                >
                                    <X className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                            <form
                                onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                }
                                onSubmit={handleSubmitAiData}
                            >
                                <div className="mb-4">
                                    <label className="flex" htmlFor="SKU ID">
                                        Features and keywords
                                        <Tooltip
                                            title="Add features and keywords for better description"
                                            arrow
                                            placement="right"
                                            className="ml-2 -tracking-tighter"
                                        >
                                            <QuestionCircleFill className="info-link cursor-pointer" />
                                        </Tooltip>
                                    </label>
                                    <textarea
                                        value={gerenateAiData?.features}
                                        name="features"
                                        onChange={handleChangeAiData}
                                        className="w-full border main-border-color rounded px-3 py-1.5 outline-none text-sm"
                                        placeholder="e.g. Organic cotten, relaxed fit"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn px-5 py-2 text-sm text-white rounded"
                                >
                                    Done
                                </button>
                            </form>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    open={generateDescriptionAiPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setGenareteDescriptionAiPopup(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="w-[350px] md:w-[600px] max-h-full">
                        <div className="relative secondary-bg-color shadow dark:bg-gray-700 p-4">
                            <div className="flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600">
                                <h6 className="capitalize font-medium  text-primary">
                                    Generate Tool description
                                </h6>
                                <button
                                    type="button"
                                    className="close-btn"
                                    onClick={() => {
                                        setGenareteDescriptionAiPopup(false);
                                    }}
                                >
                                    <X className="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                            <form
                                onKeyDown={(e) =>
                                    e.key === "Enter" && e.preventDefault()
                                }
                            >
                                <div className="mb-4">
                                    <label className="flex" htmlFor="SKU ID">
                                        Features and keywords
                                        <Tooltip
                                            title="Add features and keywords for better description"
                                            arrow
                                            placement="right"
                                            className="ml-2 -tracking-tighter"
                                        >
                                            <QuestionCircleFill className="info-link cursor-pointer" />
                                        </Tooltip>
                                    </label>
                                    <ReactQuill
                                        modules={module}
                                        className={
                                            err && description?.length === 0
                                                ? "border rounded-[10px] border-red-500 w-full h-auto"
                                                : "w-full h-auto"
                                        }
                                        theme="snow"
                                        placeholder="Enter tool description"
                                        value={description}
                                        onBlur={() =>
                                            setMeta({
                                                ...meta,
                                                meta_description:
                                                    description
                                                        ?.replace(
                                                            /<[^>]*>/g,
                                                            " "
                                                        )
                                                        ?.split(" ")
                                                        .slice(0, 50)
                                                        .join(" ") || "",
                                            })
                                        }
                                        onChange={setDescription}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <button
                                        type="button"
                                        className="btn px-5 py-2 text-white rounded"
                                        onClick={() => {
                                            setSecondDescription(description);
                                            setGenareteDescriptionAiPopup(
                                                false
                                            );
                                        }}
                                    >
                                        Done
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Dialog>
            </div>
            {htmlPP && (
                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                    <Popover
                        open={htmlPP}
                        className="h-screen popup-content"
                    // onClose={() => {
                    //   setHtmlPP(false);
                    // }}
                    >
                        <div className="max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                            <div className="w-[35rem] h-96  mb-4">
                                <label
                                    className="flex justify-between text-gray-700 text-md font-bold mb-2"
                                    htmlFor="product_name"
                                >
                                    Edit HTML:
                                    <X
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are You Sure to Leave?. Your recent changes will not be included."
                                                )
                                            ) {
                                                setHtml({
                                                    ...html,
                                                    htmlPP: false,
                                                });
                                            }
                                        }}
                                    />
                                </label>

                                <label className="flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        rows={16}
                                        value={code}
                                        onChange={(e) =>
                                            setHtml({
                                                ...html,
                                                code: e.target.value,
                                            })
                                        }
                                        placeholder="<p>Code here<p/>"
                                    />
                                </label>
                            </div>
                            <button
                                className="btn w-full text-white px-3 py-2 rounded-[10px] mx-auto"
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    );
}

export default AddWebsiteTool;
