import {
    Edit,
    Trash,
    X
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStoreCategory, deleteStoreCategory, getStoreCategory, updateStoreCategory } from "../../Redux/Action/store.action";
import { ToastContainer } from "react-toastify";

const StoreCategory = () => {
    const dispatch = useDispatch();
    const { storeCategories } = useSelector((state) => state.storeReducer)
    const [storeCategoryInfo, setStoreCategoryInfo] = useState();
    const [storeCategory, setStoreCategory] = useState([]);
    const [storeCategoryArray, setStoreCategoryArray] = useState([]);
    const [storeCategoryCount, setStoreCategoryCount] = useState(0);

    // save Group Values In Array
    const SaveStoreCategoryValue = (e) => {
        e.preventDefault();
        if (storeCategory.length > 0) {
            if (storeCategory.includes(',')) {
                let TagSeperator = storeCategory.split(',');
                let finalArray = []
                let newStoreCategory = [...storeCategoryArray, ...TagSeperator]
                newStoreCategory.filter(tag => {
                    if (tag) {
                        tag = tag.trim()
                        if (tag?.length >= 3) {
                            const searchRegex = new RegExp(`^${tag}$`, "i");
                            let exists = finalArray.some((finalTag) => searchRegex.test(finalTag))
                            if (!exists) {
                                finalArray.push(tag)
                            }
                        }
                    }
                });
                setStoreCategoryArray(finalArray);
            } else {
                if (storeCategory?.length >= 3) {
                    let category = storeCategory.trim()
                    const searchRegex = new RegExp(`^${category}$`, "i");
                    const exists = storeCategoryArray.some((tag) => searchRegex.test(tag));
                    if (!exists) {
                        setStoreCategoryArray((prev) => [...prev, category]);
                    }
                }
            }
            setStoreCategory('');
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            storeCategoryArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setStoreCategoryArray([...storeCategoryArray]);
    };

    // Get Module Information
    const getStoreCategories = async () => {
        try {
            dispatch(getStoreCategory());
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if(storeCategories) {
            setStoreCategoryInfo(storeCategories?.data);
            setStoreCategoryCount(storeCategories?.data?.categorys?.length)
        }
    },[storeCategories])

    const StoreCategorysSave = async () => {
        try {
            if (storeCategoryArray?.length) {
                dispatch(addStoreCategory(storeCategoryArray))
            }
            setStoreCategoryArray([]);
        } catch (error) {
            console.log(error)
        }
    }


    const StoreCategoryRemove = async (storeCategoryName) => {
        if (window.confirm(`Remove "${storeCategoryName}" Store Category?`)) {
            try {
                dispatch(deleteStoreCategory(storeCategoryName));
            } catch (error) {
                console.log(error)
            }
        }
    }

    const StoreCategorysUpdate = async (index) => {
        try {            
            const body = { categoryName: editData, index }
            dispatch(updateStoreCategory(storeCategoryInfo._id, body));
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getStoreCategories();
    }, []);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState();
    const [editData, setEditData] = useState()
    const handleEdit = (e, index, elem) => {
        e.preventDefault();
        setIsEdit(true);
        setEditId(index);
        setEditData(elem)
    }
    const handleSave = (e, index) => {
        e.preventDefault();
        setIsEdit(false);
        StoreCategorysUpdate(index)
    }


    return (
        <>
            <div className="w-full pb-[64px]">
                <div className="flex items-center justify-between mb-4">
                    <h1 className="text-3xl font-medium">Store Categories</h1>
                </div>
                <div>
                    <form className="">
                        <label className='flex items-center justify-between' htmlFor='storeCategory'>
                            Store Categories
                            <h1 className='text-gray-500 font-medium text-xs'>
                                Use <b className='text-black'>Enter</b> key to comma separate the Store Categories
                            </h1>
                        </label>
                        <div className="flex">
                            <textarea
                                className='block w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='store_category'
                                name='store_category'
                                value={storeCategory}
                                onChange={(e) => setStoreCategory(e.target.value)}
                                onKeyDown={(e) => (e.key === 'Enter' ? SaveStoreCategoryValue(e) : '')}
                                placeholder='e.g. Grocery, Skin care'
                            />
                            <div className='flex flex-wrap items-center mt-2'>
                            </div>
                        </div>
                        <small className="flex items-center justify-between">NOTE: Minimum 3 characters acceptable for each Category.</small>

                        {storeCategoryArray.length > 0 &&
                            <>
                                {storeCategoryArray.map((elem, index) => {
                                    if (elem !== "") {
                                        return (
                                            <span
                                                key={index}
                                                className='bg-[#f1f1f1] inline-flex items-center me-2 mb-2 mt-2 min-w-[50px] text-black text-xs text-center px-3 py-1.5 rounded-[10px]'
                                            >
                                                {elem}
                                                <X
                                                    className='text-black cursor-pointer h-[14px] w-[14px] ms-2'
                                                    onClick={() => RemoveTag(index)}
                                                />
                                            </span>
                                        );
                                    }
                                })}
                            </>
                        }
                    </form>
                    {storeCategoryArray.length > 0 &&
                        <button
                            type="button"
                            className="btn px-3 py-1 text-white rounded"
                            onClick={StoreCategorysSave}
                        >
                            Submit
                        </button>
                    }
                </div>
                <div className="flex space-x-4 mt-5">
                    <div className="w-full space-y-4">
                        <div className="w-full overflow-x-auto">
                            <table className="w-full text-left border border-slate-300">
                                <thead className="h-[45px]">
                                    <tr>
                                        <th className="flex items-center space-x-1 cursor-pointer h-[45px] min-w-[300px] px-4">
                                            <span>Store Categories</span>
                                            <span className="text-base">({storeCategoryCount})</span>
                                        </th>
                                        <th className="cursor-pointer min-w-[100px] text-right pe-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        storeCategoryInfo?.categorys?.length > 0 ?
                                            storeCategoryInfo?.categorys?.map((elem, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="bg-white w-full cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600"
                                                    >
                                                        {
                                                            isEdit && editId === index ? <input value={editData} onChange={(e) => setEditData(e.target.value)} className="px-4 py-1.5 border border-gray-300 m-2 w-full" /> :
                                                                <td className="p-3"> {elem} </td>
                                                        }
                                                        <td className="space-x-3 pe-3 text-right">
                                                            {
                                                                isEdit && editId === index ?
                                                                    <button className="text-sm inline-block underline font-medium text-link" onClick={(e) => handleSave(e, index)}>Save</button> :
                                                                    <button onClick={(e) => handleEdit(e, index, elem)}>
                                                                        <Edit className="w-[17px] h-[17px] text-blue-500" />
                                                                    </button>
                                                            }
                                                            <button onClick={() => StoreCategoryRemove(elem)}>
                                                                <Trash className="w-[17px] h-[17px] text-red-500" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : <p className="text-gray-600 p-3">No data found</p>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default StoreCategory;
