import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleGetDeleteRequest } from '../../Redux/Action/tenant.action'
import { Pagination } from '@mui/material'
import DataTable from 'react-data-table-component'
import API from '../../API'
import { ToastContainer } from 'react-toastify'

const TenentDeleteRequest = () => {
    const { deleteStore } = useSelector((state) => state.tenantReducer)
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    useEffect(() => {
        dispatch(handleGetDeleteRequest())
    }, [page])
    var startRecord = (deleteStore?.currentPage - 1) * 10 + 1;
    var endRecord = Math.min(deleteStore?.currentPage * 10, deleteStore?.totalRecords);
    const handleDeleteStoreStatusChanges = async (id, status) => {
        try {
            const body = {
                verify_status: status
            }
            const apicall = await API({ url: `/super/admin/tenant/deleteStoreStatusChanges/${id}`, method: 'patch', data: body })
            dispatch(handleGetDeleteRequest())
        } catch (error) {
            console.log(error);
        }
    }
    const columns = [
        {
            name: 'id',
            width: '60px',
            cell: (row) => <span>{row?.tenant_id || '-'}</span>,
            selector: (row) => row?.tenant_id,
            sortable: true,
        },
        {
            name: 'business name',
            minWidth: '180px',
            cell: (row) => <span>{row?.business_name || '-'}</span>,
            selector: (row) => row?.business_name,
            sortable: true,
        },
        {
            name: 'email',
            minWidth: '200px',
            cell: (row) => <span>{row?.tenant_email || '-'}</span>,
            selector: (row) => row?.tenant_email,
            sortable: true,
        },
        {
            name: 'category',
            cell: (row) => <span>{row?.business_category || '-'}</span>,
            selector: (row) => row?.business_category,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'status',
            cell: (row) => <span>{row?.verify_status?.replace("_", " ") || '-'}</span>,
            selector: (row) => row?.verify_status,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Delete request',
            right: true,
            cell: (row) => (
                row?.verify_status === "delete_request" &&
                <div className="flex justify-center space-x-2">
                    <button
                        className=" bg-green-600 text-white hover:shadow-lg transition-all duration-150 text-xs py-1 px-3 rounded-[4px] font-medium"
                        onClick={() => handleDeleteStoreStatusChanges(row._id, "delete_store")}
                    >
                        Approve
                    </button>
                    <button
                        className="bg-red-600 text-white hover:shadow-lg transition-all duration-150 text-xs py-1 px-3 rounded-[4px] font-medium"
                        onClick={() => handleDeleteStoreStatusChanges(row._id, "verified")}
                    >
                        Decline
                    </button>
                </div>
            ),
            minWidth: '200px'
        },
    ]
    return (
        <div className="flex space-x-4 w-full">
            <div className="w-full rounded space-y-3">
                <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
                    <DataTable
                        columns={columns}
                        data={deleteStore?.records}
                        selectableRowsHighlight
                        highlightOnHover
                    />
                </div>
                {deleteStore?.records?.length > 0 ? (
                    <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                        <span className='text-sm font-medium'>
                            {`${startRecord} - ${endRecord} of ${deleteStore?.records?.length}`}
                        </span>
                        <div>
                            {deleteStore?.data?.totalPages !== 1 ? (
                                <Pagination
                                    count={deleteStore?.data?.totalPages}
                                    page={page}
                                    onChange={(e, v) => setPage(v)}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {/* <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-5 max-w-full overflow-hidden">
            <div>
              <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                Search
              </span>
              <label className="relative block w-full">
                <input
                  className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search tenant..."
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) =>
                    setFilter({ ...filter, search: e.target.value })
                  }
                />
              </label>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                Sort By Verification Status
              </span>
              <div className="relative">
                <select
                  onChange={(e) => {
                    setFilter({ ...filter, v_status: e.target.value });
                  }}
                  name="verification_status"
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  value={v_status}
                >
                  <option value=''>All</option>
                  {verification_status.map((elem, index) => {

                    return (
                      <>
                        <option key={index}>{elem}</option>
                      </>
                    );
                  })}
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                Sort By Store Status
              </span>
              <div className="relative">
                <select
                  onChange={(e) => {
                    if (e.target.value === "All") {
                      setFilter({ ...filter, store_status: "" });
                    } else {
                      setFilter({
                        ...filter,
                        store_status: e.target.value,
                      });
                    }
                  }}
                  name="verification_status"
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  value={store_status}
                >
                  <option>All</option>
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
          </div>
        </div> */}
        <ToastContainer />
        </div>
    )
}

export default TenentDeleteRequest