import React, { useState } from 'react';
import API from '../../API';
import Loader from '../Loader/Loader';
import GenerateUniqueFilename from '../../controllers/GenerateUniqueFilename';
import Notification from '../Notification';
import { useDispatch } from 'react-redux';
import { importCompany } from '../../Redux/Action/company.action';
import { ToastContainer } from 'react-toastify';

const ImportCompany = () => {
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [loader, setLoader] = useState(false);
    const [csvErr, setCSVErr] = useState(false);
    const [csvFile, setCSVFile] = useState();
    const [csvname, setCSVname] = useState();
    const [importLoader, setImportLoader] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const HandleSvg = async (file) => {
        setLoader(true);
        let fileName = file?.name;
        setCSVname('');
        if (!fileName) {
            fileName = await GenerateUniqueFilename();
        }
        let parts = file?.name?.split('.');
        let Ext = parts[parts.length - 1];
        if (Ext === 'xlsx') {
            const formData = new FormData();
            formData.append('file', file, fileName);
            formData.append('folder', 'companyinfo');
            try {
                const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData });
                setCSVFile(res.data.data[0]);
                setCSVname(file?.name);
                setIsNotification(true);
                setNotificationMsg('xlsx file uploaded successfully');
                setSeverity('success');
            } catch (error) {
                setIsNotification(true);
                setNotificationMsg(error);
                setSeverity('error');
            }
            setCSVErr(false);
        } else {
            setCSVErr(true);
        }
        setLoader(false);
    };
    const importProducts = async () => {
        setImportLoader(true);
        try {            
            dispatch(importCompany(csvFile));
            setCSVFile()
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setImportLoader(false);
        }
    };
    return (
        <div className='w-full pb-[64px]'>
            <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
                <h1 className="text-3xl font-medium text-center text-primary pt-1">
                    Import Company info
                </h1>
            </div>
            <div className='w-[90%] lg:w-[70%] xl:w-[60%] bg-white p-[25px] mx-auto rounded-lg'>
                {isNotification && notificationMsg && (
                    <Notification message={notificationMsg} close={handleClose} severity={severity} />
                )}
                <div>
                    <h1 className='text-lg font-medium'>Upload company info from .xlsx file</h1>
                </div>
                <>
                    <label
                        htmlFor='dropzone-file'
                        className='flex flex-col my-4 items-center p-3 justify-center border border-dashed hover:border-gray-400 border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                    >
                        <label className='text-center cursor-pointer w-full'>
                            {csvFile ? (
                                <div className='w-full flex justify-between items-center whitespace-nowrap text-center'>
                                    <h1 className='font-medium text-gray-600'>{csvname} Uploaded</h1>
                                    <button
                                        className=' w-fit cursor-pointer lg:-tracking-tighter secondary-btn text-white text-sm font-medium py-2 px-3 lg:px-5 rounded-[4px] transition duration-300'
                                        onClick={() => setCSVFile('')}
                                    >
                                        Replace .xlsx
                                    </button>
                                </div>
                            ) : (
                                <>
                                    {loader ? <div className='nb-spinner'></div> :
                                        <>
                                            <svg
                                                className='w-8 h-8 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                                                aria-hidden='true'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 20 16'
                                            >
                                                <path
                                                    stroke='currentColor'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth={2}
                                                    d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                                                />
                                            </svg>
                                            <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                                                <span className='font-medium'>Click to upload</span> or drag and drop
                                            </p>
                                            <p className='text-md font-medium mb-1 text-gray-500 dark:text-gray-400'>
                                                Drop Your .xlsx file Here.
                                            </p>
                                        </>
                                    }
                                    <input
                                        id='dropzone-file'
                                        type='file'
                                        name='image'
                                        onChange={(e) => {
                                            HandleSvg(e.target.files[0]);
                                        }}
                                        className='hidden'
                                    />
                                </>
                            )}
                        </label>
                        {csvErr && (
                            <span className='text-xs bg-yellow-200 px-5 py-1 rounded-[4px]'>
                                <b>NOTE : </b> File must be .xlsx format
                            </span>
                        )}
                    </label>
                    <div className='inline-flex w-full'>
                        {csvFile ? (
                            <button onClick={() => importProducts()} className='btn text-sm text-white'>
                                Add company info
                            </button>
                        ) : (
                            <button className='cursor-not-allowed text-sm btn opacity-60 text-white'>Add company info</button>
                        )}
                    </div>
                </>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ImportCompany