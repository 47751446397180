import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../controllers/breadCrumbs'
import Notification from '../Notification';
import { ArrowRight } from 'react-bootstrap-icons';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorLog } from '../../Redux/Action/errorLog.action';
import { ToastContainer } from 'react-toastify';

const ErrorLogs = () => {
    const dispatch = useDispatch();
    const { logsError } = useSelector((state) => state.errorLogReducer)
    const [errorData, setErrorData] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();

    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };

    const getErrorLogs = async () => {
        try {
            dispatch(getErrorLog())
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    }

    useEffect(() => {
        getErrorLogs();
    }, [])

    useEffect(() => {
        if(logsError) {
            setErrorData(logsError?.data);
        }
    },[logsError])
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className="w-full pb-[64px]">
                <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
                    <div>
                        <Breadcrumbs crumbs={[
                            { name: 'Dashboard', path: `/dashboard` },
                            { name: 'Errorlog', path: `/error-logs` },
                        ]} />
                        <h1 className='text-xl md:text-2xl font-semibold text-primary'>Errorlog</h1>
                    </div>
                </div>
                <div className="w-full h-[80vh] table-text bg-white text-center border main-border-color mb-2.5 rounded-[10px] p-6 overflow-auto">
                    <ul className="space-y-4 text-lef">
                        {errorData?.length && errorData?.map((elem, index) => {
                            let idx = elem.indexOf(' - ')
                            let date = elem.slice(0, idx)
                            let error = elem.slice(idx + 1)
                            return (
                            <li key={index} className="flex items-center space-x-3 rtl:space-x-reverse">
                                <ArrowRight />
                                <span>{moment(date).tz('Asia/Kolkata').format('DD-MM-YYYY')}</span>
                                <span>{error}</span>
                            </li>
                        )})
                        }
                    </ul>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default ErrorLogs