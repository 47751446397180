import {
  ArrowDown,
  ArrowUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Edit,
  Eye,
  Filter,
  PlusCircle,
  Trash,
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from '../../controllers/breadCrumbs';
import ReactPaginate from "react-paginate";
import { GetAnnauncements, multipleDelete } from "../../Redux/Action/annauncement.action";
import { useDispatch, useSelector } from "react-redux";
const MyAnnouncements = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [announcements, setAnnouncements] = useState();
  const [Page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [iconVisible, setIconVisible] = useState(false);
  const [filterDD, setFilterDD] = useState(false);
  const [filter, setFilter] = useState({ search: "", a_type: '' });
  const [orderBy, setOrderBy] = useState({
    orderByValue: "",
    isDecending: false,
  });
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  // Sorting Data
  const SortData = (sort_by) => {
    setSortBy(sort_by);
    setIconVisible(true);
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: "DESC", isDecending: true });
    } else {
      setOrderBy({ orderByValue: "ASC", isDecending: false });
    }
  };

  useEffect(() => {
    dispatch(GetAnnauncements(Page, limit, sortBy, filter, orderBy));
  }, [Page, limit, sortBy, filter, orderBy]);
  const { allAnnauncements } = useSelector((state) => state.annauncementsReducer)
  // //   Get Announcements
  // const GetAnnouncements = async () => {
  //   await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/super/admin/announcement/GetAnnouncements?search=${filter.search}&page=${Page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}&announcement_type=${filter.a_type}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "content-type": "application/json",
  //         token,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => setAnnouncements(data));
  // };
  // useEffect(() => {
  //   GetAnnouncements();
  // }, [Page, limit, sortBy, orderBy, filter.search, filter.a_type]);

  // Api call For delete  Announcement
  const HandleMultipleDelete = async (id) => {
    if (window.confirm("Delete Category?")) {
      const announcements = selectedRowsId?.length > 0 ? selectedRowsId : [id]
      dispatch(multipleDelete(announcements));
      dispatch(GetAnnauncements(Page, limit, sortBy, filter, orderBy));
    } else {
      toast.warn("Announcement s Not Deleted", {
        position: "top-right",
      });
    }
  };

  // table Data
  const columns = [
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
            onClick={() => SortData("announcement_title")}
          >
            Title
          </button>
          {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ChevronUp className="w-4 ms-1 h-4" />
            ) : (
              <ChevronDown className="w-4 ms-1 h-4" />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      minWidth: "200px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">
          {row.announcement_title}
        </span>
      ),
    },
    {
      name: "TYPE",
      minWidth: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">{row.announcement_type}</span>
      ),
    },
    {
      name: 'Short Description',
      cell: (row) => <p className="line-clamp-2">{row.short_description}</p>,
      minWidth: '300px'
    },
    {
      name: "TAG",
      minWidth: "200px",
      cell: (row) => <p className="line-clamp-2">{row.tag}</p>,
    },
    {
      name: <p className="w-full text-right block">ACTION</p>,
      cell: (row) => (
        <div className="space-x-3 w-full flex items-center justify-end">
          <button
            onClick={(e) => {
              navigate("/announcement/updateannouncement", {
                state: { ...row },
              });
            }}
          >
            <button className="">
              <Edit className="w-[17px] h-[17px] text-blue-500" />
            </button>
          </button>

          <button onClick={() => HandleMultipleDelete(row._id)}>
            <button className="">
              <Trash className="w-[17px] h-[17px] text-red-500" />
            </button>
          </button>
          <button
            onClick={() =>
              navigate(`/announcement/${row._id}`)
            }
          >
            <button className="">
              <Eye className="w-[17px] h-[17px]" />
            </button>
          </button>
        </div>
      ),
    },
  ];

  //   Pagination
  const HandlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  return (
    <div className=" w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[{ name: 'Dashboard', path: `/dashboard` }, { name: 'Tenant', path: `/tenants` }]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Announcement</h1>
        </div>
        <div className="flex space-x-3">
          <Link
            to={"/announcement/createannouncement"}
            className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
            type="submit"
          //   onClick={HandleSubmit}
          >
            <PlusCircle className="w-4 h-4 me-2" />
            Add Announcement
          </Link>
          <button
            className="filter-btn rounded w-10 h-10"
            onClick={() => setFilterDD(!filterDD)}
          >
            <Filter className="inline-flex rounded-md w-5 h-5" />
          </button>
        </div>
      </div>
      <details
        className={
          selectedRowsId?.length === 0
            ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
            : `cursor-pointer group w-[200px] mb-1.5 relative`
        }
      >
        <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
          <span> Bulk Selection</span>
          <span
            className={
              selectedRowsId?.length === 0
                ? "transition group-open:rotate-0"
                : "transition group-open:rotate-180"
            }
          >
            <svg
              fill="none"
              height={14}
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width={14}
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <div
          className={
            selectedRowsId?.length === 0
              ? `hidden`
              : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
          }
        >
          <button
            className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
            onClick={() => HandleMultipleDelete()}
          >
            <Trash className="w-3.5 h-3.5" />
            <span>Delete selection</span>
          </button>
        </div>
      </details>
      <div className="w-full flex space-x-4">
        <div className="w-full space-y-4">
          <div className="w-full overflow-x-auto">
            <div className="w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]">
              <DataTable
                columns={columns}
                data={allAnnauncements?.records}
                fixedHeader
                selectableRows
                fixedHeaderScrollHeight="700px"
                onSelectedRowsChange={({ selectedRows }) => {
                  setSelectedRowsId(selectedRows?.map((x) => x._id));
                }}
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </div>
          {allAnnauncements?.data && (
            <div className="bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]">
              <div className="hidden sm:block">
                <p className="text-sm flex text-gray-700 space-x-4">
                  <div>
                    Current Page :
                    <span className="font-semibold ms-1">
                      {allAnnauncements?.data ? allAnnauncements?.data.currentPage : ""}
                    </span>
                  </div>
                  <div>
                    Total Records :
                    <span className="font-semibold ms-1">
                      {allAnnauncements?.data ? allAnnauncements?.data?.totalRecords : ""}
                    </span>
                  </div>
                </p>
              </div>
              <div>
                {allAnnauncements?.data?.totalPages !== 1 ? (
                  <ReactPaginate
                    previousLabel={<ChevronLeft className="w-4 h-4" />}
                    nextLabel={<ChevronRight className="w-4 h-4" />}
                    breakLabel={"..."}
                    pageCount={allAnnauncements?.data?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={HandlePageClick}
                    containerClassName="isolate inline-flex space-x-2"
                    pageLinkClassName="relative z-10 w-7 h-7 hover:bg-gray-200 hover:text-black rounded-full flex items-center justify-center text-sm font-medium"
                    previousClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center font-semibold hover:border-2 hover:text-gray-500 transition-all duration-150 text-sm"
                    nextClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center hover:border font-semibold hover:text-gray-500 transition-all duration-300 text-sm"
                    breakClassName="relative z-10 w-8 h-8 rounded-full hover:bg-gray-200 flex items-center justify-center text-black font-semibold border-2"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
          <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
            <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
              <Filter className="me-1 w-4 h-4" />
              Filter
            </p>
            <hr className="border-[#a0d0eb] my-3"></hr>
            <div className="grid gap-y-3 max-w-full overflow-hidden">
              <div>
                <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                  Search
                </span>
                <label className="relative block w-full">
                  <input
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    placeholder="Search for anything..."
                    type="text"
                    name="search"
                    value={filter.search}
                    onChange={(e) => setFilter({ search: e.target.value })}
                  />
                </label>
              </div>
              <div>
                <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                  Limit
                </span>
                <div className="relative">
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                    }}
                    name="verification_status"
                    className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    value={limit}
                  >
                    <option value="">All</option>
                    <option >10</option>
                    <option >20</option>
                    <option >50</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>
              <div>
                <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                  Sort by
                </span>
                <div className="relative">
                  <select
                    name="receiver"
                    value={filter.a_type}
                    onChange={(e) => setFilter({ ...filter, a_type: e.target.value })}
                    className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  >
                    <option value="">All</option>
                    <option value="admin">Admin</option>
                    <option value="affiliate">Affiliate</option>
                    <option value="customer">Customer</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>

              {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort By Store Status
                </span>
                <div>
                  <>
                    <select
                      onChange={(e) => {
                        if (e.target.value === "All") {
                          setFilter({ ...filter, store_status: "" });
                        } else {
                          setFilter({
                            ...filter,
                            store_status: e.target.value,
                          });
                        }
                      }}
                      name="verification_status"
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                      value={store_status}
                    >
                      <option>All</option>
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </select>
                  </>
                </div>
              </div> */}
            </div>
          </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default MyAnnouncements;
