import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalStoreProducts } from "../../Redux/Action/store.action";
import { useParams } from "react-router-dom";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Filter, } from "feather-icons-react/build/IconComponents";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "../../controllers/breadCrumbs";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";

const TotalProducts = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [iconVisible, setIconVisible] = useState(false);
  const { allStoreProducts } = useSelector((state) => state.storeReducer);
  const [filter, setFilter] = useState({
    filterDD: false,
    search: "",
    limit: 10,
  });
  const [sortData, setSortData] = useState({
    sort_by: "createdAt",
    order_by: "DESC",
    isDecending: false,
  });
  const { sort_by, order_by, isDecending } = sortData;
  const { filterDD, search, limit } = filter;

  // Sorting Data
  const SortData = (value) => {
    if (!isDecending) {
      setSortData({ sort_by: value, order_by: "DESC", isDecending: true });
    } else {
      setSortData({ sort_by: value, order_by: "ASC", isDecending: false });
    }
  };

  useEffect(() => {
    dispatch(
      getTotalStoreProducts(params?.tenant_id, page, limit, sort_by, order_by, search)
    );
  }, [page, limit, sort_by, order_by, search]);
  //   Pagination
  const HandlePageClick = (data) => {
    setPage(data.selected + 1);
  };
  const columns = [
    {
      name: 'id',
      width: '80px',
      cell: (row) => <span className='capitalize'>{row?.product_id}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onClick={() => SortData("product_name")}
        onMouseEnter={() => {
          setIconVisible(true);
        }}
      >
        <span className={`flex uppercase items-center`}>Name
          {iconVisible && sort_by === "product_name" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 ms-1" />
            ) : (
              <ChevronDown className="w-4 h-4 ms-1" />
            )
          ) : (
            <></>
          )}</span>

      </button>,
      minWidth: '100px',
      cell: (row) => <span>{row?.product_name}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onClick={() => SortData("selling_price")}
        onMouseEnter={() => {
          setIconVisible(true);
        }}
      >
        <span className={`flex uppercase items-center`}>
          {iconVisible && sort_by === "selling_price" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 me-1" />
            ) : (
              <ChevronDown className="w-4 h-4 me-1" />
            )
          ) : (
            <></>
          )}
          Selling price
        </span>

      </button>,
      minWidth: '100px',
      right: true,
      cell: (row) => <span>₹{row?.selling_price}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onClick={() => SortData("quantity")}
        onMouseEnter={() => {
          setIconVisible(true);
        }}
      >
        <span className={`flex uppercase items-center`}>
          {iconVisible && sort_by === "quantity" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 me-1" />
            ) : (
              <ChevronDown className="w-4 h-4 me-1" />
            )
          ) : (
            <></>
          )}
          quantity
        </span>
      </button>,
      minWidth: '100px',
      right: true,
      cell: (row) => <span>{row?.quantity}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onClick={() => SortData("sold_count")}
        onMouseEnter={() => {
          setIconVisible(true);
        }}
      >
        <span className={`flex uppercase items-center`}>
          {iconVisible && sort_by === "sold_count" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 me-1" />
            ) : (
              <ChevronDown className="w-4 h-4 me-1" />
            )
          ) : (
            <></>
          )}
          sold count
        </span>

      </button>,
      minWidth: '100px',
      cell: (row) => <span>{row?.sold_count}</span>,
      right: true
    },
    {
      name: 'product type',
      minWidth: '150px',
      cell: (row) => <span className='capitalize'>{row?.product_type}</span>,
    },
    {
      name: 'stock status',
      minWidth: '150px',
      right: true,
      cell: (row) =>
        row?.stock_status === "in_stock" ? <span className="bg-green-100 px-3 py-1 rounded-[4px] font-medium text-xs text-green-600">In Stock</span> : <span className="bg-red-100 px-3 py-1 rounded-[4px] font-medium text-xs text-red-600">Out Of Stock</span>
    },
  ]
  return (
    <div className="w-full pb-[64px]">
      <div className="flex items-center justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Stores', path: `/all-stores` },
            { name: 'Products', path: `/products` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Products</h1>
        </div>
        <button
          className="filter-btn rounded w-7 h-7"
          onClick={() => setFilter({ ...filter, filterDD: !filterDD })}
        >
          <Filter className="inline-flex w-4 h-4" />
        </button>
      </div>
      <div className="flex space-x-4 w-full">
        <div className="w-full rounded space-y-4">
          <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
            <DataTable
              columns={columns}
              data={allStoreProducts?.records}
              selectableRowsHighlight
              highlightOnHover
            />
          </div>
          {allStoreProducts ? (
            <div className="bg-white border mt-2 border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[4px]">
              <div className="hidden sm:block">
                <p className="text-sm flex text-gray-700 space-x-4">
                  <div>
                    Current Page :
                    <span className="font-semibold ms-1">
                      {allStoreProducts?.currentPage || ""}
                    </span>
                  </div>
                  <div>
                    Total Records :
                    <span className="font-semibold ms-1">
                      {allStoreProducts?.totalRecords || ""}
                    </span>
                  </div>
                </p>
              </div>
              <div>
                {allStoreProducts?.totalPages !== 1 ? (
                  <ReactPaginate
                    previousLabel={<ChevronLeft className="w-4 h-4" />}
                    nextLabel={<ChevronRight className="w-4 h-4" />}
                    breakLabel={"..."}
                    pageCount={allStoreProducts?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={HandlePageClick}
                    containerClassName="isolate inline-flex space-x-2"
                    pageLinkClassName="relative z-10 w-7 h-7 hover:bg-gray-200 hover:text-black rounded-full flex items-center justify-center text-sm font-medium"
                    previousClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center font-semibold hover:border-2 hover:text-gray-500 transition-all duration-150 text-sm"
                    nextClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center hover:border font-semibold hover:text-gray-500 transition-all duration-300 text-sm"
                    breakClassName="relative z-10 w-8 h-8 rounded-full hover:bg-gray-200 flex items-center justify-center text-black font-semibold border-2"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-5 max-w-full overflow-hidden">
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Search
              </span>
              <label className="relative block w-full">
                <input
                  className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) =>
                    setFilter({ ...filter, search: e.target.value })
                  }
                />
              </label>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                limit
              </span>
              <label className="relative block w-full">
                <select
                  value={limit}
                  onChange={(e) =>
                    setFilter({ ...filter, limit: e.target.value })
                  }
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                </select>
                <div className="select-arrow"></div>
              </label>
            </div>
            {/* <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Verification Status
                  </span>
                  <div>
                    {
                      <>
                        <select
                          onChange={(e) => {
                            setFilter({ ...filter, v_status: e.target.value });
                          }}
                          name="verification_status"
                          className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                          value={v_status}
                        >
                          <option value=''>All</option>
                          {verification_status.map((elem, index) => {

                            return (
                              <>
                                <option key={index}>{elem}</option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    }
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TotalProducts;
