import { toast } from "react-toastify";
import API from "../../API";
import { VIEW_BANK_DETAIL } from "../action.type";

export const getSettledPayments = () => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: "/super/admin/payOuts/getSattlements", method: 'post' });

      dispatch({
        type: "GET_STTLED_PAYMENTS",
        payload: ApiCall,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const getTobeSettled = () => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: "/super/admin/payOuts/amountsToBeSettled", method: 'get' });
      dispatch({
        type: "PAYMENT_TOBE_SETTLE",
        payload: ApiCall,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const makePayment = (tenants) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/payOuts/payoutSettlement`, method: 'post', data: {tenants}});
      await dispatch(getTobeSettled())
      await dispatch(getSettledPayments())
    } catch (error) {
      console.log(error);
    }
  };
};

export const viewBankDetails = (tenants) => {
  return async (dispatch) => {
    try {
      const ApiCall =  await API({ url: `/super/admin/store/bank/BankDetailOfClient/${tenants}`, method: 'post' });
      if(ApiCall?.data) {
        dispatch({type: VIEW_BANK_DETAIL, payload: ApiCall.data})
      }
    } catch (error) {
      toast.error(error);
    }
  };
};
