import { toast } from "react-toastify";
import API from "../../API";
import { GET_BLOG_CATEGORYS, GET_DYNAMIC_PAGES, GET_JOBS, GET_PARTICULAR_BLOG, GET_PARTICULAR_BLOG_CATEGORY, GET_PARTICULAR_DYNAMIC_PAGE, GET_PARTICULAR_JOB, GET_PARTICULAR_THEME, GET_PARTICULAR_TOOL, GET_THEME, GET_TOOLS, GET_WEBSITE_BLOG } from "../action.type";

export const getSEBlogs = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blog/getSEBlogs`, method: 'get', params: params
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_WEBSITE_BLOG,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const updateSEBlog = (id, data, fromBlogPage) => {
    return async (dispatch) => {
        try {
            await API({ url: `/super/admin/se_site/blog/updateSEBlog/${id}`, method: 'put', data: data });
            if(fromBlogPage) {
                dispatch(getSEBlogs());
            } else {
                window.location.href = '/site-data/blog';
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const deleteSEBlog = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blog/deleteSEBlog`, method: 'post', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                dispatch(getSEBlogs());
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const getSEBlogCategorys = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blogcategory/getSEBlogCategorys`, method: 'get', params: params
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_BLOG_CATEGORYS,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addSEBlog = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blog/addSEBlog`, method: 'post', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message)
                window.location.href = '/site-data/blog'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getPerticularSEBlog = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blog/getSEBlog/${id}`, method: 'get'
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_BLOG,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateSEBlogCategory = (id, data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blogcategory/updateSEBlogCategory/${id}`, method: 'put', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/site-data/blog-category'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deleteSEBlogCategory = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blogcategory/deleteSEBlogCategory`, method: 'post', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                dispatch(getSEBlogCategorys());
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addSEBlogCategory = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blogcategory/addSEBlogCategory`, method: 'post', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/site-data/blog-category'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularBlogCategory = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/blogcategory/getSEBlogCategory/${id}`, method: 'get',
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_BLOG_CATEGORY,
                    payload: ApiCall?.data
                })
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getJobs = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/job/GetJobs`, method: 'get', params: params
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_JOBS,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateJob = (id, data, fromSites) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/job/updateJob/${id}`, method: 'put', data: data,
            });
            if(ApiCall?.status === 200) {
                if(!fromSites) {
                    window.location.href = '/site-data/jobs'
                } else {
                    dispatch(getJobs())
                }
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const DeleteJob = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/job/DeleteJob`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                dispatch(getJobs())
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addJob = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/job/addJob`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                window.location.href = '/site-data/jobs'
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularJob = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/job/GetJob/${id}`, method: 'get',
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_JOB,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getTools = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/tool/GetTools`, method: 'get', params: params,
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_TOOLS,
                    payload: ApiCall.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateTool = (id, data, fromSites) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/tool/updateTool/${id}`, method: 'put', data: data,
            });
            if(ApiCall?.status === 200) {
                if(!fromSites) {
                    window.location.href = '/site-data/jobs'
                } else {
                    dispatch(getTools())
                }
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deleteTool = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/tool/DeleteTool`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {                
                dispatch(getTools())
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addTool = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/tool/addTool`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                window.location.href = '/site-data/tool';
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularTool = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/tool/GetTool/${id}`, method: 'get',
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_TOOL,
                    payload: ApiCall?.data
                })
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getThemes = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/theme/GetThemes`, method: 'get', params: params
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_THEME,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateTheme = (id, data, fromThemes) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/theme/updateTheme/${id}`, method: 'put', data: data,
            });
            if(ApiCall?.status === 200) {
                if(!fromThemes) {
                    window.location.href = '/site-data/themes'
                } else {
                    dispatch(getThemes());
                }
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deleteTheme = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/theme/DeleteTheme`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                dispatch(getThemes());
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addTheme = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/theme/addTheme`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/site-data/themes'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularTheme = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/theme/GetTheme/${id}`, method: 'get',
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_THEME,
                    payload: ApiCall?.data
                })
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getdynamicPages = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/dynamicpage/getdynamicpages`, method: 'get',
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_DYNAMIC_PAGES,
                    payload: ApiCall?.data
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addDynamicPage = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/dynamicpage/adddynamicpage`, method: 'post', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/site-data/page'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getparticularDynamicPage = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/dynamicpage/getparticulardynamicpage/${id}`, method: 'get'
            });
            if(ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_DYNAMIC_PAGE,
                    payload: ApiCall?.data
                })
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateDynamicPage = (id, data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/dynamicpage/updatedynamicpage/${id}`, method: 'put', data: data
            });
            if(ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/site-data/page'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deleteDynamicPage = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/se_site/dynamicpage/deletedynamicpage`, method: 'post', data: data,
            });
            if(ApiCall?.status === 200) {
                dispatch(getdynamicPages());
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};