import { toast } from "react-toastify";
import API from "../../API";
import { CUSTOM_DOMAIN_ERROR, GET_CUSTOM_DOMAIN_DETAIL } from "../action.type";

export const getCustomDomainDetail = (domain) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/tenant/getCustomDomainDetai`, method: 'post', data: { custom_domain: domain }
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_CUSTOM_DOMAIN_DETAIL,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            dispatch({
                type: CUSTOM_DOMAIN_ERROR,
                payload: error
            });
            toast.error(error);
        }
    };
};

export const domainCleanup = (domain) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/tenant/domain_cleanup`, method: 'post', data: { custom_domain: domain }
            });
            if (ApiCall?.data) {
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};