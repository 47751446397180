import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Edit,
  Filter,
  Move,
  PlusCircle,
  Trash,
} from 'feather-icons-react/build/IconComponents';
import { Pagination, Tooltip } from '@mui/material';
import DataTable from 'react-data-table-component';
import Loader from '../../Loader/Loader';
import { TrashFill, CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import Notification from '../../Notification';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { getInitialPage, replacePageInUrl } from '../../../controllers/PageHandleFromQuery';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup'
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDummyCategory, getCategoriesWithAllParent } from '../../../Redux/Action/dummyCategory.action';
// import UpdateCategory from './UpdateCategory';
const DummyCategoryTable = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch();
  const { dummyCategoryAllParent } = useSelector((state) => state.dummyCategoryReducer)
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState(location?.state ? location.state : '');
  const [pending, setPending] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  const [categoryWithParent, setAllCategoryWithParent] = useState([])
  var startRecord = (dummyCategoryAllParent?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(dummyCategoryAllParent?.data?.currentPage * limit, dummyCategoryAllParent?.data?.totalRecords);
  useEffect(() => {
    replacePageInUrl(Page)
  }, [Page])
  // fetch All Categories
  useEffect(() => {
    if(search===''||search?.length>2){
      GetCategoriesWithAllParent()
    }
  }, [search])

  const GetCategoriesWithAllParent = async () => {
    try {
      const params = { page: Page, limit, searchkey: search?.length > 2 ? search : '', sort_by: sortBy, order_by : orderBy.orderByValue }
      dispatch(getCategoriesWithAllParent(params))
        setPending(false);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetCategoriesWithAllParent();
  }, [Page, limit, sortBy, orderBy]);

  useEffect(() => {
    if(dummyCategoryAllParent) {
      setAllCategoryWithParent(dummyCategoryAllParent);
      if (Number(startRecord) > Number(dummyCategoryAllParent?.data?.totalRecords)) {
        setPage(Page - 1)
      }
    }
  },[dummyCategoryAllParent])

  const [selectedRowsId, setSelectedRowsId] = useState([]);

  // Sorting Data
  const SortData = () => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
  };
  const columns = [
  
    {
      name: 'Image',
      width: '100px',
      cell: (row) => <img width={50} height={50} 
      onError={(e) => {e.target.src = NOPHOTO_IMAGE}} 
      src={row?.image || NOPHOTO_IMAGE}
      className='p-1' />,
    },
    {
      name: (
        <div className='flex items-center'>
          <button type='button' className={`uppercase flex ${iconVisible && sortBy === 'category_name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('category_name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('category_name')}>
            Category name
          </button>
          {
            iconVisible && sortBy === 'category_name' ?

              !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>

          }
        </div>
      ),
      width: '380px',
      cell: (row) => (
        <span className='line-clamp-1 capitalize'>
           {row.parent_category}
        </span>
      ),
    },
    {
      name: 'Description',
      cell: (row) => <p className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.description?.replace(/<img[^>]*>/g, '') || '-' }}></p>,
    },
    {
      width: '100px',
      name: <div className='block text-right w-full'>Action</div>,
      cell: (row) => (
        <div className='space-x-3 w-full flex justify-end items-center'>
          <button
            type='button'
            className='text-xs font-thin text-blue-500 hover:text-blue-600'
            onClick={()=>navigate(`/dummy-data/dummy-category/${row?.category_slug_name}`)}
          >
            <Tooltip title='Edit' arrow>
              <Edit className='w-[17px] h-[17px]' />
            </Tooltip>
          </button>
          {
            row?.default !== true ?
              <Tooltip title='Delete' arrow>
                <button className='inline-block'  onClick={() => {setSelectedRowsId([row?._id]);setConfirmationPopup(true)}}>
                  <Trash className='w-[17px] h-[17px] text-red-500' />
                </button>
              </Tooltip> : <></>
          }
        </div>
      ),
    },
  ];
  const [confirmationPopup, setConfirmationPopup] = useState(false)
  // Api call For delete  Product
  const HandleMultipleDelete = async (id) => {
    if (window.confirm('Delete Category?')) {
      try {
        const body = {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
        }
        dispatch(deleteDummyCategory(body));
        setConfirmationPopup(false);        
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsNotification(true)
      setNotificationMsg('Category not deleted!')
      setSeverity('warn')
    }
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value)
  }
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(true);
  return (
    <>
      <ConfirmationPopup heading="Delete Category" subheading="Do you really want to delete this category from your store?" confirmationPopup={confirmationPopup} handleCloseConfirm={handleCloseConfirm} runFunction={HandleMultipleDelete}/>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-x-hidden overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-4'>
          <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Dummy-data', path: `/dummy-data` },
              { name: 'Categories', path: '/dummy-category' },
            ]} />
            <h1 className='text-xl md:text-2xl font-medium text-primary'>Categories</h1>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => navigate('/dummy-data/dummy-category/add-dummy-categories')}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              Add New Category
            </button>

          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between'>
              <details className={selectedRowsId?.length === 0 ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative` : `cursor-pointer group w-[200px] mb-1.5 relative`}>
                <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center font-medium list-none'>
                  <span> Bulk Selection</span>
                  <span className={selectedRowsId?.length === 0 ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div className={selectedRowsId?.length === 0 ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
                  <button
                    className='text-gray-600 hover:bg-gray-100 w-full px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                    onClick={() => setConfirmationPopup(true)}
                  >
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                </div>
              </details>
              <button
                className={'filter-btn'}
                onClick={() => setFilter(!filter)}
              >
                <Tooltip title='Filter' arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </button>
            </div>
            <div className={filter ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative' : 'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full'>
                <div className='w-full  transition-all duration-100 border mb-2.5 border-gray-300 category-table inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
                  <DataTable
                    columns={columns}
                    data={categoryWithParent?.data?.records}
                    selectableRows
                    // onRowClicked={handleRowClick}
                    onSelectedRowsChange={({ selectedRows }) => {
                      setSelectedRowsId(selectedRows?.map((x) => x._id));
                    }}
                    selectableRowsHighlight
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<Loader />}
                  />
                </div>
                {/* Paginator */}
                {categoryWithParent.data ? (
                  <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <div className='hidden sm:block'>
                      <p className='flex w-full text-black space-x-4'>
                        <span className='text-sm font-medium'>
                          {
                            limit === "all" ? `${categoryWithParent?.data?.totalRecords}` + " " + "Records" :
                              `${startRecord} - ${endRecord} of ${categoryWithParent?.data?.totalRecords}`
                          }
                        </span>
                      </p>
                    </div>
                    <div>
                      {categoryWithParent?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={categoryWithParent?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filter ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
                <p className='flex text-gray-600 font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  Filter
                </p>
                <hr className='border-gray-300 my-3'></hr>
                <div className='grid gap-y-5 overflow-hidden'>

                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Search
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full font-normal placeholder placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                        placeholder='Search Categories'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Set items limit
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border border-gray-300 px-3 py-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => { e.target.value === "all" ? setLimit('all') : setLimit(e.target.value); setPage(1) }}
                      >
                        {categoryWithParent?.data?.totalRecords > 10 ? <><option value='10'>10</option></> : <></>}
                        {categoryWithParent?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {categoryWithParent?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DummyCategoryTable;
