import { GET_DUMMY_CATEGORY_ALLPARENTS, GET_CATEGORY_PRODUCT, GET_CATEGORY, GET_PARTICULAR_CATEGORY } from "../action.type";
import API from "../../API";
import { toast } from "react-toastify";



export const getCategoriesWithAllParent = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/GetCategoriesWithAllParent`, method: 'get', params: params
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_DUMMY_CATEGORY_ALLPARENTS,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deleteDummyCategory = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/deleteDummyCategory`, method: 'post', data: data
            });
            if (ApiCall?.status === 200) {
                dispatch(getCategoriesWithAllParent());
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getProductsForCategory = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummyproduct/GetProductsForCategory`, method: 'get'
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_CATEGORY_PRODUCT,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getCategories = (params) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/GetCategoriesWithAllParent`, method: 'get', params: params
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_CATEGORY,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addDummyCategory = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/addDummyCategory`, method: 'post', data: data
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/dummy-data/dummy-category';
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularDummyCategory = (id) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/getDummyCategory/${id}`, method: 'get'
            });
            if (ApiCall?.status === 200) {
                dispatch({
                    type: GET_PARTICULAR_CATEGORY,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addCategoryInProduct = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummyproduct/addCategoryInProduct`, method: 'post', data: data
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateDummyCategory = (id, data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/dummydata/dummycategory/updateDummyCategory/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                window.location.href = '/dummy-data/dummy-category'
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};