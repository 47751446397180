import {
  Filter,
  PlusCircle,
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getExtensions, updateModule } from "../../Redux/Action/plugin.action";
import { ToastContainer } from "react-toastify";

const GetPlugin = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [moduleInfo, setModuleInfo] = useState();
  const [filterDD, setFilterDD] = useState(false);
  const { superExtensions } = useSelector((state) => state.pluginReducer);
  const [filter, setFilter] = useState({ limit: 10, tag: '', page: 1, search: '' })
  const { page, limit, search, tag } = filter
  // Get Module Information
  const GetModules = async () => {
    try {
      const params = { extension_type: tag, limit, page, search }
      dispatch(getExtensions(params));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(superExtensions) {
      setModuleInfo(superExtensions);
    }
  },[superExtensions])
  useEffect(() => {
    GetModules();
  }, [tag, page, limit, search]);

  // Update Module
  const UpdateModule = async (id, value) => {
    const body = { status: value }
    const params = { extension_type: tag, limit, page, search }
    dispatch(updateModule(id, body, params));
  };
  var startRecord = (moduleInfo?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(moduleInfo?.data?.currentPage * limit, moduleInfo?.data?.totalRecords);
  return (
    <>
      <div className="w-full pb-[64px]">
        <div className="flex items-center justify-between mb-4">
          <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Apps', path: `/apps` },
            ]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Apps</h1>
          </div>
          <div className="flex  space-x-3">
            <button onClick={() => navigate("/apps/appreviews")} className="secondary-btn flex items-center text-sm font-medium py-2 px-5 rounded-md text-white">
              Apps Reviews
            </button>
            <button onClick={() => navigate("/apps/add-app")} className="btn flex items-center text-sm font-medium py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-800 text-white">
              <PlusCircle className="w-4 h-4 me-2" />
              Add New App
            </button>
            <button
              className="filter-btn w-10 h-10"
              onClick={() => setFilterDD(!filterDD)}
            >
              <Filter className="inline-flex w-5 h-5 " />
            </button>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full space-y-3">
            <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
              <table className="w-full text-left">
                <thead className="h-[45px]">
                  <tr>
                    <th className="cursor-pointer min-w-[300px] px-4">
                      App Name
                    </th>
                    <th className="cursor-pointer min-w-[200px]">Type</th>
                    <th className="cursor-pointer">
                      Short Description
                    </th>
                    <th className="cursor-pointer min-w-[100px] text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y border-gray-300">
                  {moduleInfo?.data?.records?.length <=0 ? (
                    <p className="text-gray-600 p-3">No data found</p>
                  ) : (
                    moduleInfo?.data &&
                    moduleInfo?.data?.records.map((elem, index) => {
                      return (
                        <tr
                          key={index}
                          className="bg-white cursor-pointer text-left text-sm text-gray-600"
                        >
                          <td
                            onClick={() => navigate(`/apps/${elem?._id}`)}
                            className="p-3.5"
                          >
                            {elem.extension_name}
                          </td>
                          <td onClick={() => navigate(`/apps/${elem?._id}`)}>
                            {elem.extension_type}
                          </td>
                          <td onClick={() => navigate(`/apps/${elem?._id}`)}>
                            <p className="line-clamp-2">{elem.short_description}</p>
                          </td>
                          <td className="space-x-3 text-center">
                            <input
                              className="relative h-3.5 w-8 pr-8  appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              name="is_required"
                              checked={elem.status}
                              onChange={(e) =>
                                UpdateModule(elem._id, !elem.status)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {moduleInfo?.data?.records?.length > 0 ? (
              <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                <span className='text-sm font-medium'>
                  {
                    limit === "all" ? `${moduleInfo?.data?.totalRecords} Records` :
                      `${startRecord} - ${endRecord} of ${moduleInfo?.data?.totalRecords}`
                  }
                </span>
                <div>
                  {moduleInfo?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={moduleInfo?.data?.totalPages}
                      page={filter?.page}
                      onChange={(e, v) => setFilter({ ...filter, page: v })}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
            <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
              <Filter className="me-1 w-4 h-4" />
              Filter
            </p>
            <hr className="border-[#a0d0eb] my-3"></hr>
            <div className="grid gap-y-5 max-w-full overflow-hidden">
              {/* <div className="flex flex-wrap max-w-full rounded-sm border border-slate-300 bg-white items-center">
                <p className="text-sm text-gray-600 font-semibold mr-2 border-r border-gray-200 px-4 py-2">
                  Display
                </p>
                <button className="w-8 h-8  text-slate-400 hover:bg-slate-300 ease-in duration-100 hover:text-black flex items-center justify-center mr-2 rounded-l-md">
                  <List className="w-5 h-5" />
                </button>
                <button className="w-8 h-8  text-slate-400 hover:bg-slate-300 ease-in duration-100 hover:text-black flex items-center justify-center rounded-r-md">
                  <Grid className="w-5 h-5" />
                </button>
              </div> */}
              <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Search
                </span>
                <label className="relative block w-full">
                  <input
                    className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    placeholder="Search for anything..."
                    type="text"
                    name="search"
                    value={search}
                    onChange={(e) =>
                      setFilter({ ...filter, search: e.target.value })
                    }
                  />
                </label>
              </div>

              <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort By Store Status
                </span>
                <div className="relative">
                  <select
                    onChange={(e) => {
                      if (e.target.value === "All") {
                        setFilter({ ...filter, tag: "" });
                      } else {
                        setFilter({
                          ...filter,
                          tag: e.target.value,
                        });
                      }
                    }}
                    name="verification_status"
                    className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    value={filter.tag}
                  >
                    <option>All</option>
                    <option>Featured</option>
                    <option>POS</option>
                    <option>SMS</option>
                    <option>Payments</option>
                    <option>DMS</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>
              <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Limit
                </span>
                <div className="relative">
                  <select
                    onChange={(e) => {
                      if (e.target.value === "All") {
                        setFilter({ ...filter, limit: "" });
                      } else {
                        setFilter({
                          ...filter,
                          limit: e.target.value,
                        });
                      }
                    }}
                    name="verification_status"
                    className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    value={filter.limit}
                  >
                    <option>All</option>
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default GetPlugin;
