import { toast } from "react-toastify";
import API from "../../API";
import { GET_ERROR_LOG } from "../action.type";

export const getErrorLog = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/tenant/getErrorLogs`, method: 'get',
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_ERROR_LOG,
                    payload: ApiCall?.data
                });
                toast.success(ApiCall?.data?.message)
            }
        } catch (error) {
            toast.error(error)
        }
    };
};