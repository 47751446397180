import React, { useEffect, useState } from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import { Edit, PlusCircle, Trash } from 'feather-icons-react/build/IconComponents'
import { useNavigate } from 'react-router-dom'
import { Pagination, Tooltip } from '@mui/material'
import Loader from '../../Loader/Loader'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDynamicPage, getdynamicPages } from '../../../Redux/Action/website.action'
import { ToastContainer } from 'react-toastify'

const ViewDynamicPages = () => {
    const dispatch = useDispatch();
    const { dynamicPages } = useSelector((state) => state.websiteReducer);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const [pending, setPending] = useState(true)
    const [selectedRowsId, setSelectedRowsId] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const navigate = useNavigate();
    const [storePages, setStorePages] = useState()
    const handleGetPage = async () => {
        try {
            dispatch(getdynamicPages());
        } catch (error) {
            console.log(error);
        } finally {
            setPending(false)
        }
    }
    useEffect(() => {
        handleGetPage()
    }, [])

    useEffect(() => {
        if(dynamicPages) {
            setStorePages(dynamicPages?.data);
        }
    }, [dynamicPages])

    useEffect(() => {
        localStorage.setItem('limit', limit ? limit : 10);
    }, [limit]);
    var startRecord = (storePages?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(storePages?.currentPage * limit, storePages?.totalRecords);

    const handleDeletePage = (id) => {
        if (window.confirm("Are You Sure To remove This Page?")) {
            try {
                const body = {
                    id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
                }
                dispatch(deleteDynamicPage(body));
            } catch (error) {
                console.log(error);
            }
        }
    }
    const columns = [
        {
            name: "page name",
            cell: row => <p className='line-clamp-2'>{row?.pages_name}</p>,
            selector: (row) => row?.pages_name,
            sortable: true,
        },
        {
            name: "ACTION",
            width: '110px',
            right: true,
            cell: (row) => <div className='flex items-center space-x-2 justify-center'>
                <div>
                    <Tooltip title='Edit' arrow>
                        <button className=''>
                            <Edit
                                className='w-[17px] h-[17px] text-blue-500'
                                onClick={() => navigate(`/site-data/page/update-page/${row?.page_slug_name}`)}
                            />
                        </button>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title='Delete' arrow>
                        <button className=''>
                            <Trash
                                className='w-[17px] h-[17px] text-red-500'
                                onClick={() => handleDeletePage(row._id)}
                            />
                        </button>
                    </Tooltip>
                </div>
            </div>
        }
    ]
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
                <div className='flex items-end justify-between pb-4'>
                    <div>
                        <Breadcrumbs />
                        <h1 className='text-xl md:text-2xl font-medium text-primary'>Website Pages</h1>
                    </div>
                    <div className='flex items-center'>
                        <button
                            className='flex items-center btn text-white text-sm font-medium py-2 px-8 rounded-[10px] transition duration-300'
                            onClick={() => navigate(`/site-data/page/add-page`)}
                        >
                            <PlusCircle className="w-[18px] h-[18px] me-2" />
                            Add New page
                        </button>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='w-full  transition-all duration-100 mb-3 inline-block h-auto me-4 border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden'>
                        <DataTable
                            columns={columns}
                            data={storePages?.records}
                            selectableRows
                            onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                            selectableRowsHighlight
                            highlightOnHover
                            progressPending={pending}
                            progressComponent={<Loader />}
                        />
                    </div>
                    {storePages ? (
                        <div className='bg-white flex border border-gray-300 flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                            <div className='hidden sm:block'>
                                <p className='flex w-full text-black space-x-4'>
                                    <span className='text-sm font-medium'>
                                        {
                                            limit === "all" ? `${storePages?.totalRecords}` + " " + "Records" :
                                                `${startRecord} - ${endRecord} of ${storePages?.totalRecords}`
                                        }
                                    </span>
                                </p>
                            </div>
                            <div>
                                {storePages && storePages?.totalPages !== 1 ? (
                                    <Pagination
                                        count={storePages?.totalPages}
                                        page={page}
                                        onChange={(e, v) => setPage(v)}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <ToastContainer />
                </div>
            </div>
        </>
    )
}

export default ViewDynamicPages