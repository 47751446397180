import { GET_RESTRITED_IP } from "../action.type";

const initialState={
    getIp: [],
}                                 

export const restrictedIPReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_RESTRITED_IP:
        return {
            ...state,
            getIp: action.payload,
        };
      
      default:
        return state;
    }
  }
