import { toast } from "react-toastify";
import API from "../../API";
import { GET_RESTRITED_IP } from "../action.type";

export const getRestrictedIP = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/getIPs`, method: 'get',
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_RESTRITED_IP,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const addIPs = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/addIPs`, method: 'post', data: {ips: data}
            });
            if (ApiCall?.data) {
                dispatch(getRestrictedIP());
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const removeIP = (ip) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/removeIP`, method: 'delete', data: {ip}
            });
            if (ApiCall?.data) {
                dispatch(getRestrictedIP());
                toast.success(ApiCall?.data?.message);
            }
        } catch (error) {
            toast.error(error)
        }
    };
};