import { toast } from "react-toastify";
import API from "../../API";
import { GET_EXTENSION_REVIEW, GET_PARTICULAR_EXTENSION, GET_PARTICULAR_EXTENSION_REVIEW, GET_SUPER_EXTENSION } from "../action.type";

export const getExtensions = (params) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/GetSuperExtensions`, method: 'get', params: params
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_SUPER_EXTENSION,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateModule = (id, data, params) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/UpdateSuperExtension/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                dispatch(getExtensions(params));
                toast.success(ApiCall?.data?.data?.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const addExtension = (data) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/AddSuperExtension`, method: 'post', data: data
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/apps'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularExtension = (id) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/GetSuperExtension/${id}`, method: 'get',
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_PARTICULAR_EXTENSION,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updateExtension = (id, data) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/UpdateSuperExtension/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                window.location.href = '/apps'
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const updatePlanExtension = (id, data) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/UpdateSuperExtensionPlan/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                dispatch(getParticularExtension(id))
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const deletePlanExtension = (id, appId) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/DeleteSuperExtensionPlan/${id}`, method: 'delete'
            });
            if (ApiCall?.status === 200) {
                dispatch(getParticularExtension(appId))
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getExtensionReviews = () => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/review/GetExtensionReviews`, method: 'get'
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_EXTENSION_REVIEW,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};

export const getParticularExtensionReviews = (id, params) => {
    return async (dispatch) => {
        try {
            const ApiCall = await API({
                url: `/super/admin/extension/review/GetExtensionReview/${id}`, method: 'get', params: params
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_PARTICULAR_EXTENSION_REVIEW,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error);
        }
    };
};