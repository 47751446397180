import React, { useEffect, useState } from "react";
import { Eye, Filter} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { GetTenants } from "../../Redux/Action/tenant.action";
import Breadcrumbs from "../../controllers/breadCrumbs";
import DataTable from "react-data-table-component";
import { Pagination } from "@mui/material";
import { ToastContainer } from "react-toastify";

const AllStores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    filterDD: false,
    search: "",
    limit: 10,
  });
  const [sortData, setSortData] = useState({
    sort_by: "createdAt",
    order_by: "DESC",
    isDecending: false,
  });
  const { sort_by, order_by, isDecending } = sortData;
  const { filterDD, search, limit } = filter;
  const { allTenants } = useSelector((state) => state.tenantReducer)
  useEffect(() => {
    if (search === '' || search.length > 2) {
      dispatch(GetTenants(page, limit, 'verified', '', search))
    }
  }, [search])
  useEffect(() => {
    dispatch(GetTenants(page, limit, 'verified', '', search))
  }, [sort_by, order_by, page, limit]);

  const column = [
    {
      name: 'Id',
      width: '80px',
      cell: (row) => <span className='capitalize'>{row?.tenant_id || '-'}</span>,
      selector: (row) => row?.tenant_id,
      sortable: true,
    },
    {
      name: 'tenant name',
      minWidth: '180px',
      cell: (row) => <span className='capitalize'>{row?.tenant_name || '-'}</span>,
      selector: (row) => row?.tenant_name,
      sortable: true,
    },
    {
      name: 'customer domain',
      minWidth: '180px',
      cell: (row) => <span>{row?.customer_domain}</span>,
      selector: (row) => row?.customer_domain,
      sortable: true,
    },
    {
      name: 'date',
      minWidth: '180px',
      right: true,
      cell: (row) => <span className='capitalize'>{moment(row?.createdAt).format('lll')}</span>,
      selector: (row) => row?.createdAt,
      sortable: true,
    },
    {
      name: 'action',
      width: '100px',
      right: true,
      cell: (row) => <button className="pe-3 inline-block">
        <Eye
          className="w-[18px] h-[18px] text-black"
          onClick={() => navigate(`/all-stores/${row?.tenant_name}`)}
        />
      </button>,
    },
  ]
  var startRecord = (allTenants?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allTenants?.currentPage * limit, allTenants?.totalRecords);
  return (
    <div className="w-full overflow-hidden pb-[64px]">
      <div className="flex items-center justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Stores', path: `/all-stores` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Stores</h1>
        </div>
        <button
          className="filter-btn rounded w-10 h-10"
          onClick={() => setFilter({ ...filter, filterDD: !filterDD })}
        >
          <Filter className="inline-flex w-5 h-5" />
        </button>
      </div>
      <div className="flex space-x-4 w-full">
        <div className="w-full rounded space-y-3">
          <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
            <DataTable
              columns={column}
              data={allTenants?.records}
              selectableRowsHighlight
              highlightOnHover
            // progressComponent={<Loader />}
            />
          </div>
          {allTenants?.records?.length > 0 ? (
            <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
              <span className='text-sm font-medium'>
                {
                  limit === "all" ? `${allTenants?.totalRecords} Records` :
                    `${startRecord} - ${endRecord} of ${allTenants?.totalRecords}`
                }
              </span>
              <div>
                {allTenants?.totalPages !== 1 ? (
                  <Pagination
                    count={allTenants?.totalPages}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-5 max-w-full overflow-hidden">
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Search
              </span>
              <label className="relative block w-full">
                <input
                  className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) =>
                    setFilter({ ...filter, search: e.target.value })
                  }
                />
              </label>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                limit
              </span>
              <label className="relative block w-full">
                <select
                  value={limit}
                  onChange={(e) =>
                    setFilter({ ...filter, limit: e.target.value })
                  }
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                </select>
                <div className="select-arrow"></div>
              </label>
            </div>
            {/* <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Verification Status
                  </span>
                  <div>
                    {
                      <>
                        <select
                          onChange={(e) => {
                            setFilter({ ...filter, v_status: e.target.value });
                          }}
                          name="verification_status"
                          className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                          value={v_status}
                        >
                          <option value=''>All</option>
                          {verification_status.map((elem, index) => {

                            return (
                              <>
                                <option key={index}>{elem}</option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    }
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllStores;
