import { GET_ERROR_LOG } from "../action.type";

const initialState={
    logsError: [],
}                                 

export const errorLogReducer=(state=initialState, action)=> {
    switch (action.type) {
      case GET_ERROR_LOG:
        return {
            ...state,
            logsError: action.payload,
        };
      
      default:
        return state;
    }
  }
