import {
    Eye,
    Filter,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tooltip } from "@mui/material";
import DataTable from "react-data-table-component";
import Loader from "../../Loader/Loader";
import { CaretDownFill, CaretUpFill, StarFill } from "react-bootstrap-icons";
import Notification from "../../Notification";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import {
    getInitialPage,
    replacePageInUrl,
} from "../../../controllers/PageHandleFromQuery";
import { NOPHOTO_IMAGE } from "../../../Assets/StaticData/StaticImage";
import { useDispatch, useSelector } from "react-redux";
import { getExtensionReviews } from "../../../Redux/Action/plugin.action";
import { ToastContainer } from "react-toastify";

function AllAppReview() {
    const [Page, setPage] = useState(getInitialPage());
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { extensionsReview } = useSelector((state) => state.pluginReducer)
    const initialLimit = localStorage.getItem("limit");
    const [limit, setLimit] = useState(initialLimit);
    const [search, setSearch] = useState(location?.state ? location.state : "");
    const [appsData, setAppsData] = useState();
    const [pending, setPending] = useState(true);
    const [sortBy, setSortBy] = useState("");
    const [iconVisible, setIconVisible] = useState(false);
    const [orderBy, setOrderBy] = useState({
        orderByValue: "ASC",
        isDecending: false,
    });
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    useEffect(() => {
        replacePageInUrl(Page);
    }, [Page]);
    // Sorting Data
    const SortData = (sort_by) => {
        if (!orderBy.isDecending) {
            setOrderBy({ orderByValue: "DESC", isDecending: true });
        } else {
            setOrderBy({ orderByValue: "ASC", isDecending: false });
        }
        GetAppsReviews();
    };

    var startRecord = (appsData?.data?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(
        appsData?.data?.currentPage * limit,
        appsData?.data?.totalRecords
    );
    useEffect(() => {
        if (search === "" || search?.length > 2) {
            GetAppsReviews();
        }
    }, [search]);
    const GetAppsReviews = async () => {
        try {
            const params = {
                page: Page,
                limit: limit,
                searchkey: search?.length > 2 ? search : "",
                sort_by: sortBy,
                order_by: orderBy.orderByValue
            }
            dispatch(getExtensionReviews(params));
            setPending(false);
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    useEffect(() => {
        if(extensionsReview) {
            setAppsData(extensionsReview);
            if (
                Number(startRecord) > Number(extensionsReview?.data?.totalRecords)
            ) {
                setPage(Page - 1);
            }
        }
    },[extensionsReview])
    const tableData = appsData?.data?.records;
    const [selectedRowsId, setSelectedRowsId] = useState([]);
    useEffect(() => {
        GetAppsReviews();
    }, [Page, limit]);
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    useEffect(() => {
        localStorage.setItem("limit", limit ? limit : 10);
    }, [limit]);

    const [filter, setFilter] = useState(true);
    const columns = [
        {
            name: "Image",
            width: "100px",
            cell: (row) => (
                <img
                    width={40}
                    height={50}
                    onError={(e) => {
                        e.target.src = NOPHOTO_IMAGE;
                    }}
                    src={row?.image || NOPHOTO_IMAGE}
                    className="p-1"
                />
            ),
        },
        {
            name: (
                <div className="flex items-center">
                    <button
                        className={`uppercase flex ${iconVisible && sortBy === "title"
                            ? "text-gray-700"
                            : ""
                            }`}
                        onMouseEnter={() => {
                            setIconVisible(true);
                            setSortBy("title");
                        }}
                        onMouseLeave={() => {
                            setIconVisible(false);
                            setSortBy("");
                        }}
                        onClick={() => SortData("title")}
                    >
                        Apps Title
                        {iconVisible && sortBy === `title` ? (
                            !orderBy.isDecending ? (
                                <CaretUpFill className="ms-2" />
                            ) : (
                                <CaretDownFill className="ms-2" />
                            )
                        ) : (
                            <></>
                        )}
                    </button>
                </div>
            ),
            cell: (row) => <p className="line-clamp-2">{row?.extension}</p>,
        },
        {
            name: (
                <div className="flex items-center">
                    <button
                        className={`uppercase flex ${iconVisible && sortBy === "rating"
                            ? "text-gray-700"
                            : ""
                            }`}
                        onMouseEnter={() => {
                            setIconVisible(true);
                            setSortBy("rating");
                        }}
                        onMouseLeave={() => {
                            setIconVisible(false);
                            setSortBy("");
                        }}
                        onClick={() => SortData("rating")}
                    >
                        Apps rating
                        {iconVisible && sortBy === `rating` ? (
                            !orderBy.isDecending ? (
                                <CaretUpFill className="ms-2" />
                            ) : (
                                <CaretDownFill className="ms-2" />
                            )
                        ) : (
                            <></>
                        )}
                    </button>
                </div>
            ),
            cell: (row) => <>
                {row?.average_review >= 5 ? (
                    <div className='inline-flex items-center space-x-2 bg-[#00b67a] text-white px-3 py-0.5 rounded-[10px]'>
                        <StarFill />
                        <span className='font-medium text-sm'>{row?.average_review}</span>
                    </div>
                ) : row?.average_review >= 4 && row?.average_review < 5 ? (
                    <div className='inline-flex items-center space-x-2 bg-[#73cf11] text-white px-3 py-0.5 rounded-[10px]'>
                        <StarFill />
                        <span className='font-medium text-sm'>{row?.average_review}</span>
                    </div>
                ) : row?.average_review >= 3 && row?.average_review < 4 ? (
                    <div className='inline-flex items-center space-x-2 bg-[#ffce00] text-white px-3 py-0.5 rounded-[10px]'>
                        <StarFill />
                        <span className='font-medium text-sm'>{row?.average_review}</span>
                    </div>
                ) : row?.average_review >= 2 && row?.average_review < 3 ? (
                    <div className='inline-flex items-center space-x-2 bg-[#ff8622] text-white px-3 py-0.5 rounded-[10px]'>
                        <StarFill />
                        <span className='font-medium text-sm'>{row?.average_review}</span>
                    </div>
                ) : row?.average_review < 2 ? (
                    <div className='inline-flex items-center space-x-2 bg-[#ff3722] text-white px-3 py-0.5 rounded-[10px]'>
                        <StarFill />
                        <span className='font-medium text-sm'>{row?.average_review}</span>
                    </div>
                ) : (
                    <></>
                )}
            </>,
        },
        {
            name: (
                <div className="flex items-center">
                    <button
                        className={`uppercase flex ${iconVisible && sortBy === "review_count"
                            ? "text-gray-700"
                            : ""
                            }`}
                        onMouseEnter={() => {
                            setIconVisible(true);
                            setSortBy("review_count");
                        }}
                        onMouseLeave={() => {
                            setIconVisible(false);
                            setSortBy("");
                        }}
                        onClick={() => SortData("review_count")}
                    >
                        Apps Review Count
                        {iconVisible && sortBy === `review_count` ? (
                            !orderBy.isDecending ? (
                                <CaretUpFill className="ms-2" />
                            ) : (
                                <CaretDownFill className="ms-2" />
                            )
                        ) : (
                            <></>
                        )}
                    </button>
                </div>
            ),
            cell: (row) => <p className="line-clamp-2">{row?.review_count}</p>,
        },
        {
            name: "ACTION",
            width: "110px",
            right: true,
            cell: (row) => (
                <div className="flex items-center space-x-2 justify-center">
                    <Tooltip title='View' arrow>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/apps/appreview/${row?._id}`);
                            }}
                        >
                            <Eye className='w-[17px] h-[17px]' />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto overflow-x-hidden pb-[64px]">
                <div className="flex items-end justify-between pb-4">
                    <div>
                        <Breadcrumbs />
                        <h1 className="text-xl md:text-2xl font-medium text-primary">
                            Apps Reviews
                        </h1>
                    </div>
                </div>
                <div className="flex justify-between space-x-3 w-full relative">
                    <div className="w-full space-y-1.5">
                        <div className="flex items-center justify-between">
                            <div></div>
                            <button
                                className={"filter-btn"}
                                onClick={() => setFilter(!filter)}
                            >
                                <Tooltip title="Filter" arrow>
                                    <button className="">
                                        <Filter className="h-5 w-5" />
                                    </button>
                                </Tooltip>
                            </button>
                        </div>
                        <div
                            className={
                                filter
                                    ? "flex w-full h-full overflow-hidden 2xl:space-x-4 relative"
                                    : "flex w-full h-full overflow-hidden relative"
                            }
                        >
                            <div className="w-full">
                                <div className="w-full  transition-all duration-100 mb-3 inline-block h-auto me-4 border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden">
                                    <DataTable
                                        columns={columns}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={pending}
                                        progressComponent={<Loader />}
                                    />
                                </div>
                                {appsData?.data ? (
                                    <div className="bg-white flex border border-gray-300 flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]">
                                        <div className="hidden sm:block">
                                            <p className="flex w-full text-black space-x-4">
                                                <span className="text-sm font-medium">
                                                    {limit === "all"
                                                        ? `${appsData?.data?.totalRecords}` +
                                                        " " +
                                                        "Records"
                                                        : `${startRecord} - ${endRecord} of ${appsData?.data?.totalRecords}`}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            {appsData.data &&
                                                appsData?.data?.totalPages !== 1 ? (
                                                <Pagination
                                                    count={
                                                        appsData?.data
                                                            ?.totalPages
                                                    }
                                                    page={Page}
                                                    onChange={(e, v) =>
                                                        setPage(v)
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div
                                className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filter
                                    ? "w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300"
                                    : "p-0 w-0  -right-full"
                                    }`}
                            >
                                <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                                    <Filter className="me-1 w-4 h-4" />
                                    Filter
                                </p>
                                <hr className="border-gray-300 my-3"></hr>
                                <div className="grid gap-y-5">
                                    <div>
                                        <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                                            Search
                                        </span>
                                        <label className="relative block w-full">
                                            <input
                                                className="w-full placeholder placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-1.5 pl-3 pr-12 focus:outline-none text-sm"
                                                placeholder="Search Apps"
                                                type="text"
                                                name="search"
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    setPage(1);
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                                            Set items limit
                                        </span>
                                        <div className="relative">
                                            <select
                                                className="outline-none rounded-[10px] w-full text-slate-500 text-sm border border-gray-300 px-3 py-1.5"
                                                name="limit"
                                                value={limit}
                                                onChange={(e) => {
                                                    setPage(1);
                                                    e.target.value === "all"
                                                        ? setLimit("all")
                                                        : setLimit(
                                                            e.target.value
                                                        );
                                                }}
                                            >
                                                {appsData?.data?.totalRecords >
                                                    10 ? (
                                                    <>
                                                        <option value="10">
                                                            10
                                                        </option>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {appsData?.data?.totalRecords >
                                                    20 ? (
                                                    <option value="20">
                                                        20
                                                    </option>
                                                ) : (
                                                    <></>
                                                )}
                                                {appsData?.data?.totalRecords >
                                                    50 ? (
                                                    <option value="50">
                                                        50
                                                    </option>
                                                ) : (
                                                    <></>
                                                )}
                                                <option value="all">All</option>
                                            </select>
                                            <div className="select-arrow"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default AllAppReview;
