import { CUSTOM_DOMAIN_ERROR, GET_CUSTOM_DOMAIN_DETAIL } from "../action.type";

const initialState = {
    loader: true,
    domainDetails: [],
    error: null
}

export const customDomainReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_DOMAIN_DETAIL:
            return {
                ...state,
                loader: false,
                error: null,
                domainDetails: action?.payload,
            };

        case CUSTOM_DOMAIN_ERROR:
            return {
                ...state,
                loader: false,
                domainDetails: null,
                error: action.payload
            };

        default:
            return state;
    }
}
