import { toast } from "react-toastify";
import API from "../../API";
import { GET_COMPANY_DATA, GET_PERTICULER_COMPANY_DATA } from "../action.type";

export const handleGetCompanyData = (page, limit,search) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/companyInfo/getCompanyList`, method: 'get', params: { page, limit, searchkey: search }
      });
      dispatch({
        type: GET_COMPANY_DATA,
        payload: ApiCall?.data?.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const handleAddCompany = (body) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/companyInfo/AddCompany`,method: 'post', data: body})
    } catch (error) {
      console.log(error);
    }
  }
}

export const handleEditCompany = (id,body) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/companyInfo/updateCompany/${id}`, method: 'put', data: body})
    } catch (error) {
      console.log(error);
    }
  }
}

export const handleDeleteCompany = (id, page, limit) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/companyInfo/DeleteCompany`, method: 'post', data: {
        id: id
      }})
      dispatch(handleGetCompanyData(page, limit))
    } catch (error) {
      console.log(error);
    }
  }
}

export const getPerticulerCompany = (id) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/companyInfo/getCompany/${id}`, method: 'get' })
      dispatch({
        type: GET_PERTICULER_COMPANY_DATA,
        payload: ApiCall?.data?.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const importCompany = (url) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/companyInfo/ImportCompanyInfo`, method: 'post', data: { url } })
      if(ApiCall?.status === 200) {
        toast.success("Successfully imported company information");
        window.location.href = "/company"
      }
    } catch (error) {
      toast.error(error);
    }
  }
}