import { GET_STTLED_PAYMENTS, PAYMENT_TOBE_SETTLE, VIEW_BANK_DETAIL } from "../action.type";

const initialState = {
  settlePayments: null,
  tobeSettlePayment: null,
  viewBankDetail: {}
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STTLED_PAYMENTS:
      return {
        ...state,
        settlePayments: action.payload?.data?.data,
      };
    case PAYMENT_TOBE_SETTLE:
      return {
        ...state,
        tobeSettlePayment: action.payload?.data?.data 
      };
    case VIEW_BANK_DETAIL:
      return {
        ...state,
        viewBankDetail: action.payload 
      };
    default:
      return state;
  }
};
