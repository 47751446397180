import { toast } from "react-toastify";
import API from "../../API";
import { GET_RESTRITED_DOMAIN } from "../action.type";

export const getDomains = () => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/getDomains`, method: 'get',
            });
            if (ApiCall?.data) {
                dispatch({
                    type: GET_RESTRITED_DOMAIN,
                    payload: ApiCall?.data
                });
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const addDomains = (data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/addDomains`, method: 'post', data: { domains: data }
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                dispatch(getDomains());
            }
        } catch (error) {
            toast.error(error)
        }
    };
};

export const removeDomain = (domain) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/restrictedDomains/removeDomain`, method: 'delete', data: { domain }
            });
            if (ApiCall?.status === 200) {
                toast.success(ApiCall?.data?.message);
                dispatch(getDomains());
            }
        } catch (error) {
            toast.error(error)
        }
    };
};