import {
    Autocomplete,
    Dialog,
    Menu,
    MenuItem,
    Skeleton,
    TextField
} from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Clock,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import ImageUploader from "../../../controllers/ImageUploader";
import TextEditor from "../../../controllers/TextEditor";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import { useDispatch, useSelector } from "react-redux";
import { getPerticularSEBlog, getSEBlogCategorys, updateSEBlog } from "../../../Redux/Action/website.action";
import { ToastContainer } from "react-toastify";
import { CalendarFill } from "react-bootstrap-icons";
import DatePicker from 'react-datepicker';
import { Transition } from "../../../controllers/Transition";
const UpdateWebsiteBlog = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { perticularBlog, blogCategorys } = useSelector((state) => state.websiteReducer);
    const [warn, setwarn] = useState(false);
    const [elem, setElem] = useState();
    // image upload logic
    const [image, setimage] = useState();
    const [meta, setMeta] = useState({ meta_title: "", meta_description: "" });
    const { meta_description, meta_title } = meta;
    const [SelectCategory, setSelectCategory] = useState();
    const [secondDescription, setSecondDescription] = useState();
    const [title, setTitle] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [err, setErr] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const [isSchedule, setIsSchedule] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [scheduleTimeDate, setScheduleTimeDate] = useState();
    const open = Boolean(menuAnchorEl);
    const handleClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    const handleSetImage = (value) => {
        setimage(value);
    };
    const [formSpinner, setFormSpinner] = useState(true);
    const getPerticularBlog = async () => {
        setFormSpinner(true);
        try {
            dispatch(getPerticularSEBlog(params?.blog_slug_name))
        } catch (error) {
            console.log(error);
            setFormSpinner(false);
        }
    };
    useEffect(() => {
        getPerticularBlog();
    }, [params.blog_slug_name]);

    useEffect(() => {
        if (perticularBlog) {
            setElem(perticularBlog?.data);
            setimage(perticularBlog?.data?.image);
            setSelectCategory(
                perticularBlog?.data?.blogcategory ? perticularBlog?.data?.blogcategory : []
            );
            setMeta({
                meta_title: perticularBlog?.data?.meta_title || "",
                meta_description: perticularBlog?.data?.meta_description || "",
            });
            setSortOrder(perticularBlog?.data?.sort_order || "");
            setSecondDescription(
                perticularBlog?.data?.description ? `${perticularBlog?.data?.description}` : ""
            );
            setTitle(perticularBlog?.data?.title ? `${perticularBlog?.data.title}` : "");
            setSlugName(
                perticularBlog?.data?.blog_slug_name ?? ""
            );
            setBlogTagsArray(perticularBlog?.data?.blog_tags);
            setScheduleTimeDate(
                new Date(perticularBlog?.data?.blog_publish_date) > new Date()
                  ? new Date(perticularBlog?.data?.blog_publish_date)
                  : '',
              );
            setFormSpinner(false);
        }
    }, [perticularBlog])

    // get Store Category
    // const [StoreCategory, setStoreCategory] = useState([]);
    // const GetStoreCategory = async () => {
    //     try {
    //         const ApiCall = await API({ url: `/super/admin/storecategory/getStoreCategory`, method: 'get' });
    //         setStoreCategory(ApiCall?.data?.data?.categorys);
    //     } catch (error) {
    //         setIsNotification(true);
    //         setNotificationMsg(error);
    //         setSeverity("error");
    //     }
    // };
    // useEffect(() => {
    //     GetStoreCategory();
    // }, []);

    // get Parents Category
    const [Category, setCategory] = useState([]);
    const GetCategory = async () => {
        try {
            dispatch(getSEBlogCategorys());
        } catch (error) {
            console.log(error);
        }
    };
    const [slugName, setSlugName] = useState();
    useEffect(() => {
        GetCategory();
    }, []);

    useEffect(() => {
        if (blogCategorys) {
            setCategory(blogCategorys);
        }
    }, [blogCategorys])

    const handleBlogCategory = (id, name) => {
        if (id) {
            const cat = SelectCategory.find((x) => x._id === id);
            if (!cat) {
                setSelectCategory([
                    ...SelectCategory,
                    { _id: id, blogcategory_name: name },
                ]);
            }
        }
    };
    const HandleUpdate = async (e, key, schedule) => {
        e.preventDefault();
        try {
            let body = {
                blogcategory: SelectCategory?.map((x) => x._id),
                // blog_tags: blogTagsArray,
                title: title,
                image: image,
                description: secondDescription,
                blog_slug_name: slugName,
                sort_order: sortOrder,
                blog_tags: blogTagsArray?.filter((x) => x !== ""),
                ...meta,
            }
            if (key === "draft") {
                body = {
                    ...body,
                    is_draft: true
                }
            } else if (schedule >= new Date()) {
                body = {
                    ...body,
                    blog_publish_date: schedule,
                };
            }
            dispatch(updateSEBlog(elem._id, body));
        } catch (error) {
            console.log(error);
        }
    };
    const [blogTagsValue, setBlogTagsValue] = useState([]);
    const [blogTagsArray, setBlogTagsArray] = useState([]);

    // save Group Values In Array
    const SaveblogTagsValue = () => {
        if (blogTagsValue.length > 0) {
            if (blogTagsValue.includes(",")) {
                let TagSeperator = blogTagsValue.split(",");

                let finalArray = [];
                let productTags = [...blogTagsArray, ...TagSeperator];
                productTags.filter((tag) => {
                    const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
                    let exists = finalArray.some((finalTag) =>
                        searchRegex.test(finalTag)
                    );
                    if (!exists) {
                        finalArray.push(tag.trim());
                    }
                });
                setBlogTagsArray(finalArray);
            } else {
                const searchRegex = new RegExp(`^${blogTagsValue}$`, "i");

                const exists = blogTagsArray.some((tag) =>
                    searchRegex.test(tag)
                );

                if (!exists) {
                    setBlogTagsArray((prev) => [...prev, blogTagsValue]);
                }
            }
            setBlogTagsValue("");
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setBlogTagsArray([...blogTagsArray]);
    };

    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const removeCategory = (id) => {
        const removeRelated = SelectCategory.filter((x) => x._id !== id);
        setSelectCategory(removeRelated);
    };
    const handleEditorData = (value) => {
        setSecondDescription(value)
    }
    const [enableSlug, setEnableSlug] = useState(false);
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugName(await GenerateSlugName(slug));
        }
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}

            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Update website blog - {elem?.title}
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[80%] mx-auto"
                    >
                        {formSpinner ? (
                            <div className="space-y-4">
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="flex items-center space-x-4 w-full">
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[45px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[200px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div>
                                        Media
                                        <p className="text-xs text-gray-500 font-normal">
                                            Upload captivating image to make
                                            your product stand out.
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-white group border border-gray-300  space-y-4 rounded-xl p-[25px]">
                                    <div className="w-full mb-4">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Blog Title
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            name="title"
                                            // maxLength={60}
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="e.g. Tech"
                                            onBlur={() => handleSlug(title)}
                                        // onBlur={async () => setSlugName(await GenerateSlugName(title))}
                                        />
                                        {warn && (
                                            <h1 className="text-sm text-red-500">
                                                Please enter blog title
                                            </h1>
                                        )}
                                    </div>
                                    {/* <div className="w-full mb-4">
                                        <label
                                            className="flex items-center justify-between"
                                            htmlFor="category_name"
                                        >
                                            blog Slug name
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="blog_slug_name"
                                            name="blog_slug_name"
                                            value={slugName}
                                            disabled
                                            // onChange={(e) => setSlugName(e.target.value)}
                                            // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                                        />
                                    </div> */}

                                    <div className="w-full">
                                        <label htmlFor="category_name">
                                            blog Slug name
                                            <span className="text-red-500 ms-1">
                                                *
                                            </span>
                                        </label>
                                        <div
                                            className={`flex items-center justify-between w-full border ${err && enableSlug
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                } rounded-[4px]`}
                                        >
                                            <input
                                                className="w-full  focus:outline-none"
                                                type="text"
                                                name="blog_slug_name"
                                                value={slugName}
                                                disabled={!enableSlug}
                                                onChange={(e) =>
                                                    setSlugName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {enableSlug ? (
                                                <Check
                                                    onClick={async () => {
                                                        if (slugName?.length > 0) {
                                                            setSlugName(
                                                                await GenerateSlugName(
                                                                    slugName
                                                                )
                                                            );
                                                            setEnableSlug(
                                                                !enableSlug
                                                            );
                                                            setErr(false);
                                                        }
                                                    }}
                                                    className="text-green-500 p-1 h-8 w-8"
                                                />
                                            ) : (
                                                <Edit
                                                    onClick={() =>
                                                        setEnableSlug(
                                                            !enableSlug
                                                        )
                                                    }
                                                    className="text-blue-500 p-1 h-7 w-7"
                                                />
                                            )}
                                        </div>
                                        {err && enableSlug && (
                                            <p className="text-xsm text-red-500">
                                                Please save this changes
                                            </p>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="description">
                                            Blog category
                                        </label>
                                        <Autocomplete
                                            className="autocomplete"
                                            options={
                                                Category?.data
                                                    ? Category?.data?.records
                                                    : []
                                            }
                                            getOptionLabel={(category) =>
                                                category.blogcategory_name
                                            }
                                            onChange={(e, v) =>
                                                v
                                                    ? handleBlogCategory(
                                                        v?._id,
                                                        v?.blogcategory_name
                                                    )
                                                    : ""
                                            }
                                            // value={Category?.data?.find((category) => category.blogcategory_name === elem?.blogcategory?.blogcategory_name) || null}
                                            renderInput={(Categories) => (
                                                <TextField
                                                    placeholder="Select blog category"
                                                    className="cursor-pointer"
                                                    defaultValue={
                                                        elem?.blogcategory
                                                            ?.blogcategory_name
                                                    }
                                                    {...Categories}
                                                />
                                            )}
                                        />
                                        {SelectCategory?.map((elem, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="bg-[#f5f5f5] mt-1 inline-flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded"
                                                >
                                                    {elem?.blogcategory_name}
                                                    <X
                                                        className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                        onClick={() =>
                                                            removeCategory(
                                                                elem?._id
                                                            )
                                                        }
                                                    />
                                                </span>
                                            );
                                        })}
                                    </div>
                                    <div className="mb-4">
                                        <TextEditor label={'blog description'} handleEditorData={handleEditorData} defaultValue={secondDescription} />
                                        {err &&
                                            secondDescription?.length === 0 && (
                                                <p className="text-red-500 text-xs">
                                                    Please enter description for
                                                    generate meta data
                                                </p>
                                            )}
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex items-center justify-between">
                                            <label htmlFor="Description">
                                                Blog Tags
                                            </label>
                                            <p className="text-gray-500 font-normal text-xs">
                                                Use{" "}
                                                <b className="text-black">
                                                    Enter
                                                </b>{" "}
                                                key to separate the tags
                                            </p>
                                        </div>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="blog_tags"
                                            name="blog_tags"
                                            value={blogTagsValue}
                                            onChange={(e) =>
                                                setBlogTagsValue(e.target.value)
                                            }
                                            onKeyDown={(e) =>
                                                e.key === "Enter"
                                                    ? SaveblogTagsValue()
                                                    : ""
                                            }
                                            placeholder="e.g. Black"
                                        />
                                        <div className="flex mt-1 flex-wrap items-center">
                                            {blogTagsArray &&
                                                blogTagsArray.map(
                                                    (elem, index) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded"
                                                            >
                                                                {elem}
                                                                <X
                                                                    className="h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer"
                                                                    onClick={() =>
                                                                        RemoveTag(
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </span>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Media</span>
                                            <span className="text-red-500 ms-1">
                                                *
                                            </span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Upload captivating image to make
                                                your category stand out.
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <ImageUploader
                                        alreadyImage={image}
                                        handleSetImage={handleSetImage}
                                        folder="blog"
                                        format="image"
                                    />
                                </details>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Search engine listing</span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Add a title and description to
                                                see how this product might
                                                appear in a search engine
                                                listing
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag Title"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta title
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'title');
                            }}
                          >
                            Get meta title
                          </span> */}
                                            </div>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag Title"
                                                name="meta_title"
                                                value={meta_title}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="Enter meta title"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag description"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta description
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'description');
                            }}
                          >
                            Get meta description
                          </span> */}
                                            </div>
                                            <textarea
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag description"
                                                name="meta_description"
                                                value={meta_description}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="e.g. We have best fashionable products"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="sort_order">
                                                sort order
                                            </label>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="number"
                                                id="sort_order"
                                                name="sort_order"
                                                value={sortOrder}
                                                onChange={(e) => {
                                                    setSortOrder(
                                                        e.target.value <= 0
                                                            ? 0
                                                            : e.target.value
                                                    );
                                                }}
                                                placeholder="Enter sort order"
                                            />
                                        </div>
                                    </div>
                                </details>
                                <div className="flex items-center justify-between w-full">
                                    <Link
                                        to={`/site-data/blog`}
                                        className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                                    >
                                        <ArrowLeft className="w-3 mr-1" />
                                        Go to blogs
                                    </Link>
                                    <div className='flex items-center bg-[#6a63FB] rounded text-sm font-medium'>
                                        <button
                                            className={`flex btn rounded-s px-5 py-2.5 text-sm items-center font-medium  text-white transition duration-300`}
                                            onClick={(e) =>
                                                HandleUpdate(e, elem?.is_draft === true ? 'draft' : 'publish')
                                            }
                                        >
                                            Update Blog
                                        </button>
                                        {scheduleTimeDate || elem?.is_draft === true ? (
                                            <button
                                                type='button'
                                                className='flex btn px-3 border-s border-gray-600 rounded-e py-[9px] items-center text-white transition duration-300'
                                                onClick={handleClick}
                                            >
                                                <ChevronDown className='w-[18px] h-[18px] stroke-white' />
                                            </button>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            <Menu
                                                id='basic-menu'
                                                anchorEl={menuAnchorEl}
                                                open={open}
                                                onClose={handleCloseMenu}
                                                MenuListProps={'aria-labelledby'}
                                                className='text-sm'
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                {scheduleTimeDate && (
                                                    <MenuItem
                                                        className='text-sm block'
                                                        onClick={(e) => {
                                                            setIsSchedule(true);
                                                            handleCloseMenu();
                                                        }}
                                                    >
                                                        <Clock className='w-4 h-4' />
                                                        <span className='ms-2 text-sm'>
                                                            Update Publish Schedule
                                                        </span>
                                                    </MenuItem>
                                                )}
                                                {elem?.is_draft === true && (
                                                    <MenuItem
                                                        className='text-sm block'
                                                        onClick={(e) => HandleUpdate(e, 'publish')}
                                                    >
                                                        <svg
                                                            fill='currentcolor'
                                                            version='1.1'
                                                            id='Capa_1'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            xmlnsXlink='http://www.w3.org/1999/xlink'
                                                            width='18px'
                                                            height='18px'
                                                            viewBox='0 0 31.854 31.854'
                                                            xmlSpace='preserve'
                                                        >
                                                            <g>
                                                                <path
                                                                    d='M15.94,11.735c0,0.588-0.476,1.064-1.065,1.064H5.298c-0.588,0-1.065-0.476-1.065-1.064c0-0.587,0.477-1.063,1.065-1.063
          h9.577C15.465,10.672,15.94,11.148,15.94,11.735z M21.52,11.087l5.342,4.791L15.535,28.505l-5.34-4.791L21.52,11.087z
           M20.008,16.188l-0.033-0.029c-0.127-0.114-0.293-0.172-0.461-0.163c-0.168,0.009-0.328,0.085-0.439,0.211l-6.079,6.776
          c-0.253,0.281-0.229,0.713,0.052,0.965c0.126,0.115,0.291,0.174,0.461,0.164c0.168-0.01,0.327-0.086,0.44-0.213l6.108-6.81
          C20.293,16.828,20.27,16.423,20.008,16.188z M21.27,13.751l-0.301,0.336c-0.111,0.126-0.17,0.292-0.162,0.461
          c0.01,0.169,0.086,0.328,0.211,0.441l0.035,0.031c0.127,0.113,0.293,0.171,0.461,0.162c0.17-0.009,0.328-0.085,0.44-0.211
          l0.728-0.811l-0.51-0.457C21.91,13.467,21.506,13.489,21.27,13.751z M22.035,10.51l5.341,4.791l1.312-1.462l-5.34-4.791
          L22.035,10.51z M31.047,11.21l-1.877,2.093l-5.341-4.79l1.88-2.093c0.247-0.28,0.6-0.448,0.975-0.468
          c0.375-0.021,0.742,0.109,1.021,0.359l3.234,2.903C31.52,9.735,31.569,10.629,31.047,11.21z M29.996,10.264l-3.233-2.902
          l-0.937,1.042l3.233,2.903L29.996,10.264z M9.557,24.428l5.339,4.791l-6.31,2.586c-0.243,0.098-0.521,0.051-0.716-0.125
          c-0.196-0.177-0.273-0.447-0.201-0.699L9.557,24.428z M10.045,26.13l-0.722,2.507l1.411,1.268l2.412-0.986L10.045,26.13z
           M8.215,24.285l-0.623,2.162H2.859c-1.332,0-2.413-1.08-2.413-2.412V6.493c0-1.332,1.08-2.413,2.413-2.413h2.94V3.906
          c0-0.492,0.399-0.89,0.89-0.89h0.74C7.414,2.909,7.406,2.781,7.406,2.661C7.406,1.194,8.599,0,10.067,0
          c1.469,0,2.661,1.194,2.661,2.661c0,0.12-0.01,0.248-0.023,0.354h0.749c0.492,0,0.89,0.399,0.89,0.89v0.174h2.87
          c1.332,0,2.412,1.081,2.412,2.413v4.63l-2.128,2.372V7.506c0-0.699-0.565-1.265-1.264-1.265h-1.891v1.201
          c0,0.491-0.399,0.89-0.89,0.89H6.69c-0.492,0-0.89-0.399-0.89-0.89V6.241H3.874c-0.699,0-1.265,0.567-1.265,1.265V23.02
          c0,0.701,0.567,1.266,1.265,1.266H8.215z M9.003,2.661c0,0.124,0.023,0.248,0.061,0.355h2.005c0.04-0.107,0.062-0.23,0.062-0.355
          c0-0.587-0.477-1.065-1.064-1.065C9.479,1.596,9.003,2.074,9.003,2.661z M14.949,16.341l0.991-1.105
          c-0.014-0.576-0.484-1.054-1.064-1.054H5.298c-0.588,0-1.065,0.493-1.065,1.082c0,0.587,0.477,1.082,1.065,1.082h9.577
          C14.9,16.344,14.925,16.342,14.949,16.341z M4.233,18.791c0,0.588,0.477,1.062,1.065,1.062H11.8l1.907-2.127H5.298
          C4.71,17.727,4.233,18.203,4.233,18.791z'
                                                                />
                                                            </g>
                                                        </svg>
                                                        <span className='ms-2 text-sm'>Publish Blog Post</span>
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
                <Dialog
                    open={isSchedule}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setIsSchedule(false)}
                    aria-describedby='alert-dialog-slide-description'
                >
                    <div className='w-full md:w-[35rem] p-5 rounded-[6px] secondary-bg-color shadow dark:bg-gray-700'>
                        <label
                            className='flex items-center justify-between  text-primary text-md font-medium mb-2'
                            htmlFor='product_name'
                        >
                            Update Publish Schedule
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsSchedule(false);
                                }}
                                className='close-btn'
                            >
                                <X className='w-4 h-4' />
                            </button>
                        </label>
                        <hr className='my-4 main-border-color' />
                        <div className='w-full h-[332px]'>
                            <div className='relative w-full block cursor-pointer'>
                                <DatePicker
                                    className={`${scheduleTimeDate && "bold-date"} border main-border-color focus:outline-none focus:border-gray-400 w-full`}
                                    showTimeSelect
                                    open
                                    placeholderText='Select product launch date'
                                    minDate={new Date()}
                                    minTime={new Date(0, 0, 0, 0)}
                                    maxTime={new Date(0, 0, 0, 23, 30)}
                                    selected={scheduleTimeDate}
                                    onChange={(date) => {
                                        setScheduleTimeDate(date);
                                    }}
                                    dateFormat='MMMM d, yyyy h:mm aa'
                                />
                                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                                    <CalendarFill className='text-gray-600 w-3 h-3' />
                                </span>
                            </div>
                        </div>
                        <button
                            onClick={(e) => HandleUpdate(e, 'publish', scheduleTimeDate)}
                            className='btn text-sm text-white'
                        >
                            Update_Publish_Schedule
                        </button>
                    </div>
                </Dialog>
                <ToastContainer />
            </div>
        </>
    );
};

export default UpdateWebsiteBlog;
