import { toast } from "react-toastify";
import API from "../../API";
import { ADD_ANNAUNCEMENTS, GET_ANNAUNCEMENTS, GET_PERTICULAR_DATA } from "../action.type"

export const AddAnnauncements = (title, receiver, link, short_description, tag,value) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if(token){
        let ApiCall = await API({ url: "/super/admin/announcement/AddAnnouncement", method: 'post', data: {
          announcement_title: title,
          announcement_type: receiver,
          short_description: short_description,
          description: value,
          tag: tag,
          link: link,
        }});
        if(ApiCall?.data){
          dispatch({
            type: ADD_ANNAUNCEMENTS,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const GetAnnauncements = (Page,limit,sortBy,filter,orderBy) => {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      try {
        if(token){
        let ApiCall = await API({ url: `/super/admin/announcement/GetAnnouncements`, method: 'get', params: {
          page: Page,
          limit: limit,
          search: filter.search,
          sort_by: sortBy,
          order_by: orderBy.orderByValue,
          announcement_type: filter.a_type
        }});
        if(ApiCall?.data){
          dispatch({
            type: GET_ANNAUNCEMENTS,
            payload:ApiCall
          });
        }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  export const viewAnnauncements = (id) => {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      if(id)
      try {
        if(token){
          let ApiCall = await API({ url: `/super/admin/announcement/GetAnnouncement/${id}`, method: 'get' });
          if (ApiCall?.data) {
            dispatch({
              type: GET_PERTICULAR_DATA,
              payload: ApiCall
            })
          }
        }
        } catch (error) {
          toast.error(error);
        }
    }
  }




  // export const UpdateAnnauncements = (title,value,slugName,category,_id) => {
  //   return async (dispatch) => {
  //     const token = localStorage.getItem("token");
  //     try {
  //       if(token){
  //         let ApiCall = await API({ url: `/super/admin/guide/updateGuide/${_id}`, method: 'put', data: {
  //             title: title,
  //             description: value,
  //             guide_slug_name: slugName,
  //             category: category,
  //             // extension: extension,
  //         }});
  //         if(ApiCall?.data){
  //           dispatch({
  //             type: UPDATE_GUIDE,
  //             payload:ApiCall
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  // };
    

  // export const GetDeleteAnnauncements = (selectedRowsId,id) => {
  //   if (window.confirm("Delete Guide?")) {
  //   return async (dispatch) => {
  //     const token = localStorage.getItem("token");
  //     try {
  //       if(token){
  //         let ApiCall = await API({ url: `/super/admin/guide/deleteGuide`, method: 'post', data: {
  //           guide: selectedRowsId?.length > 0 ? selectedRowsId : [id],
  //         }});
  //         dispatch({
  //           type: GET_GUIDES,
  //         });
  //         if (ApiCall.status === 200) {
  //           toast.success("Guide Removed Successfully", {
  //             position: "top-right",
  //           });
  //           dispatch(GetGuides());
  //         }
  //       }
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   };
  // }
  // };

  export const multipleDelete = (announcements) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/announcement/DeleteAnnouncements`, method: 'post', data: { announcements }
            });
            if (ApiCall?.status === 200) {
                toast.success("Announcement Removed Successfully", {
                  position: "top-right",
                });
              }
        } catch (error) {
            toast.error(error);
        }
    };
  };

  export const EditAnnouncement = (id, data) => {
    return async (dispatch) => {
        try {
            let ApiCall = await API({
                url: `/super/admin/announcement/UpdateAnnouncement/${id}`, method: 'put', data: data
            });
            if (ApiCall?.status === 200) {
                toast.success("Announcement Update Successfully");
                window.location.href = '/announcement'
              }
        } catch (error) {
            toast.error(error);
        }
    };
  };