import { GET_EXTENSION_REVIEW, GET_PARTICULAR_EXTENSION, GET_PARTICULAR_EXTENSION_REVIEW, GET_SUPER_EXTENSION } from "../action.type";

const initialState = {
    superExtensions: [],
    particularExtensions: {},
    extensionsReview: [],
    particularExtensionsReview: {},
};

export const pluginReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPER_EXTENSION:
            return {
                ...state,
                superExtensions: action.payload,
            };
        case GET_PARTICULAR_EXTENSION:
            return {
                ...state,
                particularExtensions: action.payload,
            };
        case GET_EXTENSION_REVIEW:
            return {
                ...state,
                extensionsReview: action.payload,
            };
        case GET_PARTICULAR_EXTENSION_REVIEW:
            return {
                ...state,
                particularExtensionsReview: action.payload,
            };
        
        default:
            return state;
    }
}