import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalStoreCustomers } from "../../Redux/Action/store.action";
import { useParams } from "react-router-dom";
import { ChevronDown, ChevronUp, Filter } from "feather-icons-react/build/IconComponents";
import moment from "moment-timezone";
import Breadcrumbs from "../../controllers/breadCrumbs";
import DataTable from "react-data-table-component";
import { Pagination } from "@mui/material";
import { ToastContainer } from "react-toastify";

const TotalCustomers = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const [page, setPage] = useState(1);
  const [iconVisible, setIconVisible] = useState(false);
  const { allStoreCustomers } = useSelector((state) => state.storeReducer);
  const [filter, setFilter] = useState({
    filterDD: false,
    search: "",
    limit: 10,
  });
  const [sortData, setSortData] = useState({
    sort_by: "createdAt",
    order_by: "DESC",
    isDecending: false,
  });
  const { sort_by, order_by, isDecending } = sortData;
  const { filterDD, search, limit } = filter;
  // const { allStoreCustomers } = useSelector((state) => state.storeReducer);

  // Sorting Data
  const SortData = (value) => {
    if (!isDecending) {
      setSortData({ sort_by: value, order_by: "DESC", isDecending: true });
    } else {
      setSortData({ sort_by: value, order_by: "ASC", isDecending: false });
    }
  };

  useEffect(() => {
    dispatch(getTotalStoreCustomers(params?.tenant_id, page, limit, sort_by, order_by, search));
  }, [page, limit, sort_by, order_by, search]);
  const column = [
    {
      name: 'id',
      width: '80px',
      cell: (row) => <span className='capitalize'>{row?.customer_id}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onClick={() => SortData("user.first_name")}
      >
        <span className={`flex uppercase items-center`}>Name
          {iconVisible && sort_by === "user.first_name" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 ms-1" />
            ) : (
              <ChevronDown className="w-4 h-4 ms-1" />
            )
          ) : (
            <></>
          )}</span>

      </button>,
      minWidth: '100px',
      cell: (row) => <span className='capitalize'>{row?.user?.name}</span>,
    },
    {
      name: <button
        className="cursor-pointer"
        onMouseEnter={() => {
          setIconVisible(true);
        }}
        onClick={() => SortData("user.email")}
      >
        <span className={`flex uppercase items-center`}>Email
          {iconVisible && sort_by === "user.email" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 ms-1" />
            ) : (
              <ChevronDown className="w-4 h-4 ms-1" />
            )
          ) : (
            <></>
          )}</span>

      </button>,
      minWidth: '100px',
      cell: (row) => <span>{row?.user?.email}</span>,
    },
    {
      name: 'phone',
      minWidth: '100px',
      cell: (row) => <span className='capitalize'>{row?.user?.country_code || "-"}{row?.user?.phone}</span>,
    },
    {
      name: <button className="cursor-pointer"
        onMouseEnter={() => {
          setIconVisible(true);
        }}
        onClick={() => SortData("createdAt")}>
        <span className={`flex uppercase items-center`}>Date
          {iconVisible && sort_by === "createdAt" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 ms-1" />
            ) : (
              <ChevronDown className="w-4 h-4 ms-1" />
            )
          ) : (
            <></>
          )}</span>
      </button>,
      minWidth: '100px',
      cell: (row) => <span className='capitalize'>{moment(row?.createdAt).format('lll')}</span>,
    },
    {
      name: <button onMouseEnter={() => {
        setIconVisible(true);
      }}
        onClick={() => SortData("order_count")} className="cursor-pointer text-right">
        <span className={`flex justify-end`}>
          {iconVisible && sort_by === "order_count" ? (
            !isDecending ? (
              <ChevronUp className="w-4 h-4 me-1" />
            ) : (
              <ChevronDown className="w-4 h-4 me-1" />
            )
          ) : (
            <></>
          )}
          Total orders
        </span>
      </button>,
      minWidth: '100px',
      right: true,
      cell: (row) => <span className='capitalize'>{row?.order_count}</span>,
    },
    {
      name: 'status',
      right: true,
      minWidth: '100px',
      cell: (row) =>
        <button className={`px-3 py-1 rounded-[4px] font-medium text-xs ${row?.is_blocked ? 'bg-red-200 text-red-600' : 'bg-green-200 text-green-700'} `}>
          {
            row?.is_blocked ? "Blocked" : "Unblocked"
          }
        </button>
    },
  ]
  var startRecord = (allStoreCustomers?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allStoreCustomers?.currentPage * limit, allStoreCustomers?.totalRecords);
  return (
    <div className="w-full bg-transparent h-full">
      <div className="flex items-center justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Stores', path: `/all-stores` },
            { name: 'Customers', path: `/customers` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Customers</h1>
        </div>
        <button
          className=" filter-btn rounded w-7 h-7"
          onClick={() => setFilter({ ...filter, filterDD: !filterDD })}
        >
          <Filter className="inline-flex w-4 h-4" />
        </button>
      </div>
      <div className="flex space-x-4 w-full">
        <div className="w-full rounded space-y-3">
          <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
            <DataTable
              columns={column}
              data={allStoreCustomers?.records}
              selectableRowsHighlight
              highlightOnHover
            // progressComponent={<Loader />}
            />
          </div>
          {allStoreCustomers?.records?.length > 0 ? (
            <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
              <span className='text-sm font-medium'>
                {
                  limit === "all" ? `${allStoreCustomers?.totalRecords} Records` :
                    `${startRecord} - ${endRecord} of ${allStoreCustomers?.totalRecords}`
                }
              </span>
              <div>
                {allStoreCustomers?.totalPages !== 1 ? (
                  <Pagination
                    count={allStoreCustomers?.totalPages}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-5 max-w-full overflow-hidden">
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Search
              </span>
              <label className="relative block w-full">
                <input
                  className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) =>
                    setFilter({ ...filter, search: e.target.value })
                  }
                />
              </label>
            </div>
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                limit
              </span>
              <label className="relative block w-full">
                <select
                  value={limit}
                  onChange={(e) =>
                    setFilter({ ...filter, limit: e.target.value })
                  }
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                </select>
                <div className="select-arrow"></div>
              </label>
            </div>
            {/* <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Verification Status
                  </span>
                  <div>
                    {
                      <>
                        <select
                          onChange={(e) => {
                            setFilter({ ...filter, v_status: e.target.value });
                          }}
                          name="verification_status"
                          className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                          value={v_status}
                        >
                          <option value=''>All</option>
                          {verification_status.map((elem, index) => {

                            return (
                              <>
                                <option key={index}>{elem}</option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    }
                  </div>
                </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TotalCustomers;
