import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@mui/material";
import DatePicker from "react-datepicker";
import { getTotalStoreOrders } from "../../Redux/Action/store.action";
import { useParams } from "react-router-dom";
import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Filter,
  X,
} from "feather-icons-react/build/IconComponents";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { NOPHOTO_IMAGE } from "../../Assets/StaticData/StaticImage";
import { ToastContainer } from "react-toastify";
const TotalOrders = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const orderStatus = [
    "order placed",
    "in process",
    "shipped",
    "completed",
    "canceled",
    "denied",
    // 'return',
    "return declined",
    "refunded",
    "payment failed",
    "payment pending",
  ];
  const [page, setPage] = useState(1);
  const [iconVisible, setIconVisible] = useState(false);
  const { allStoreOrders } = useSelector((state) => state.storeReducer);
  const [filter, setFilter] = useState({
    filterDD: false,
    start_date: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    ),
    end_date: new Date(),
    limit: 10,
    order_status: "",
    order_type: "",
  });
  const [showProduct, setShowProduct] = useState(false);
  const [selectedId, setselectedId] = useState();
  const [sortData, setSortData] = useState({
    sort_by: "createdAt",
    order_by: "DESC",
    isDecending: false,
  });
  const { sort_by, order_by, isDecending } = sortData;
  const { filterDD, limit, start_date, end_date, order_status, order_type } = filter;

  // Sorting Data
  const SortData = (value) => {
    if (!isDecending) {
      setSortData({ sort_by: value, order_by: "DESC", isDecending: true });
    } else {
      setSortData({ sort_by: value, order_by: "ASC", isDecending: false });
    }
  };

  useEffect(() => {
    dispatch(
      getTotalStoreOrders(
        params?.tenant_id,
        start_date || "",
        end_date || "",
        order_status,
        order_type,
        page,
        limit,
        sort_by,
        order_by
      )
    );
  }, [
    page,
    limit,
    sort_by,
    order_by,
    order_type,
    order_status,
    start_date,
    end_date,
  ]);
  //   Pagination
  const HandlePageClick = (data) => {
    setPage(data.selected + 1);
  };
  const handleClosePopup = () => {
    setShowProduct(false);
  };
  return (
    <div className="pb-[64px] w-full h-full">
      <div className="w-full overflow-hidden">
        <div className="flex items-center justify-between mb-4">
          <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Stores', path: `/all-stores` },
              { name: 'Orders', path: `/orders` },
            ]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Orders</h1>
          </div>
          <button
            className="filter-btn rounded w-7 h-7"
            onClick={() => setFilter({ ...filter, filterDD: !filterDD })}
          >
            <Filter className="inline-flex w-4 h-4" />
          </button>
        </div>
        <div className="flex space-x-4 w-full">
          <div className="w-full rounded space-y-4">
            <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
              <table className="w-full text-left">
                <>
                  <thead className="h-[45px]">
                    <tr>
                      <th className="cursor-pointer h-full px-2 min-w-[30px]">
                        order ID
                      </th>
                      <th className="cursor-pointer h-full px-2 min-w-[30px]">
                        name
                      </th>
                      <th className="cursor-pointer h-full px-2 min-w-[30px]">
                        Status
                      </th>

                      <th
                        className="  cursor-pointer px-2 min-w-[100px]"
                        onMouseEnter={() => {
                          setIconVisible(true);
                        }}
                        onClick={() => SortData("selling_price")}
                      >
                        <span
                          className={`flex whitespace-nowrap justify-end items-center ${iconVisible &&
                            sort_by === "selling_price" &&
                            "font-bold text-black"
                            }`}
                        >
                          {iconVisible && sort_by === "selling_price" ? (
                            !isDecending ? (
                              <ChevronUp className="w-4 h-4 me-1" />
                            ) : (
                              <ChevronDown className="w-4 h-4 me-1" />
                            )
                          ) : (
                            <></>
                          )}
                          Total price
                        </span>
                      </th>
                      <th className=" cursor-pointer text-center px-2 min-w-[150px]">
                        Products
                      </th>

                      <th
                        onMouseEnter={() => {
                          setIconVisible(true);
                        }}
                        onClick={() => SortData("sold_count")}
                        className="cursor-pointer"
                      >
                        Customer Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!allStoreOrders ? (
                      <h1 className="text-gray-600 text-base p-3">
                        No Orders found
                      </h1>
                    ) : (
                      allStoreOrders?.records?.map((elem, index) => {
                        return (
                          <tr
                            key={index}
                            className="bg-white text-left border-b border-slate-200 text-sm text-gray-600"
                          >
                            <td className="px-3 mt-2">{elem?.order_id}</td>
                            <td className="px-3 mt-2">{elem?.user?.name}</td>
                            <td className="inline-flex mt-2 items-center w-full">
                              {elem?.status === "payment failed" ||
                                elem?.status === "denied" ||
                                elem?.status === "return declined" ||
                                elem?.status === "canceled" ? (
                                <>
                                  {elem?.status === "canceled" ? (
                                    <span className=" px-3 py-1 rounded-[4px] font-medium text-xs  text-red-500 bg-red-100 leading-tight">
                                      cancelled
                                    </span>
                                  ) : (
                                    <span className=" inline-block px-3 py-1 rounded-[4px] font-medium text-xs  text-red-500 bg-red-100 leading-tight">
                                      {elem?.status}
                                    </span>
                                  )}
                                </>
                              ) : elem?.status === "refunded " ||
                                elem?.status === "completed" ||
                                elem?.status === "order placed" ? (
                                <>
                                  <span className="relative inline-block px-3 py-1 rounded-[4px] font-medium text-xs  text-green-600 bg-green-100 leading-tight">
                                    {elem?.status}
                                  </span>
                                </>
                              ) : elem?.status === "payment pending" ||
                                elem?.status === "in process" ||
                                elem?.status === "shipped" ? (
                                <>
                                  <span className="relative inline-block px-3 py-1 rounded-[4px] font-medium text-xs  text-yellow-600 bg-yellow-100 leading-tight">
                                    {elem?.status}
                                  </span>
                                </>
                              ) : elem?.status === "in process" ||
                                elem?.status === "processing" ? (
                                <>
                                  <span className="relative inline-block px-3 py-1 rounded-[4px] font-medium text-xs  text-blue-600 bg-blue-100 leading-tight">
                                    {elem?.status}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="relative inline-block px-3 py-1 rounded-[4px] font-medium text-xs  text-yellow-600 bg-yellow-100 leading-tight">
                                    {elem?.status}
                                  </span>
                                </>
                              )}
                            </td>
                            <td className="p-2 w-28 text-right">
                              ₹{elem?.total_price.toFixed(2)}
                            </td>
                            <td className="flex items-center justify-center w-full cursor-pointer select-none">
                              <button
                                aria-describedby={elem?._id}
                                variant="contained"
                                className="flex items-center"
                                onClick={() => {
                                  setShowProduct(!showProduct);
                                  setselectedId(elem._id);
                                }}
                              >
                                {`${elem?.products?.length} items`}{" "}
                                <ChevronDown className="w-3 h-3 ms-1 mt-1" />
                              </button>
                              {elem?._id === selectedId && showProduct && (
                                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                                  <Popover
                                    id={elem?._id}
                                    open={showProduct}
                                    onClose={handleClosePopup}
                                    className="cart-items"
                                  >
                                    <div className="px-5 pb-5 pt-2.5 w-[400px]">
                                      <div className="flex items-center justify-between">
                                        <h2 className="capitalize">
                                          {`${elem.user?.name ||
                                            elem?.guest_user?.name ||
                                            ""
                                            } - `}
                                          Cart items
                                        </h2>
                                        <X
                                          className="w-4 h-4 cursor-pointer"
                                          onClick={() => setShowProduct(false)}
                                        />
                                      </div>
                                      <hr className="my-3" />
                                      <div className="divide-y space-y-2 max-h-[550px] overflow-auto">
                                        {elem?.products?.map((elem) => {
                                          return (
                                            <div className="flex w-full pt-2 max-h-20">
                                              <div className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] overflow-hidden flex items-center justify-center border border-gray-300">
                                                <img
                                                  onError={(e) => { e.target.src = NOPHOTO_IMAGE }}
                                                  src={elem?.image || NOPHOTO_IMAGE}
                                                  alt={elem?.image || NOPHOTO_IMAGE}
                                                  // src={elem?.image}
                                                  className="max-w-full mx-auto block max-h-full object-contain"
                                                />
                                              </div>
                                              <div className="flex items-start ps-2 w-full justify-between">
                                                <div className="">
                                                  <p className="text-md font-medium line-clamp-1">
                                                    {elem?.name}
                                                  </p>
                                                  <p className="text-xs font-medium mt-0.5 text-gray-500">{`Total: ₹${elem?.product_total?.toFixed(
                                                    2
                                                  )}`}</p>
                                                </div>
                                                <p className="flex items-center justify-end text-xs min-w-[55px]">
                                                  {`₹${elem?.selling_price?.toFixed(
                                                    2
                                                  )}`}
                                                  <X className="w-3 h-3 mx-0.5" />
                                                  {elem?.quantity}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </Popover>
                                </div>
                              )}
                            </td>
                            <td>
                              {elem?.is_guest ? "Guest" : "User"}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </>
              </table>
            </div>
            {allStoreOrders ? (
              <div className="bg-white border mt-2 border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[4px]">
                <div className="hidden sm:block">
                  <p className="text-sm flex text-gray-700 space-x-4">
                    <div>
                      Current Page :
                      <span className="font-semibold ms-1">
                        {allStoreOrders?.currentPage || ""}
                      </span>
                    </div>
                    <div>
                      Total Records :
                      <span className="font-semibold ms-1">
                        {allStoreOrders?.totalRecords || ""}
                      </span>
                    </div>
                  </p>
                </div>
                <div>
                  {allStoreOrders?.totalPages !== 1 ? (
                    <ReactPaginate
                      previousLabel={<ChevronLeft className="w-4 h-4" />}
                      nextLabel={<ChevronRight className="w-4 h-4" />}
                      breakLabel={"..."}
                      pageCount={allStoreOrders?.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={HandlePageClick}
                      containerClassName="isolate inline-flex space-x-2"
                      pageLinkClassName="relative z-10 w-7 h-7 hover:bg-gray-200 hover:text-black rounded-full flex items-center justify-center text-sm font-medium"
                      previousClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center font-semibold hover:border-2 hover:text-gray-500 transition-all duration-150 text-sm"
                      nextClassName="relative z-10 w-7 h-7 rounded-full text-black flex items-center justify-center hover:border font-semibold hover:text-gray-500 transition-all duration-300 text-sm"
                      breakClassName="relative z-10 w-8 h-8 rounded-full hover:bg-gray-200 flex items-center justify-center text-black font-semibold border-2"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {filterDD && (
            <div className="z-10 absolute right-5 h-auto xl:relative xl:right-0 w-auto xl:w-1/3 p-3 border border-gray-300 bg-white rounded-[4px] overflow-hidden">
              <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                <Filter className="me-1 w-4 h-4" />
                Filter
              </p>
              <hr className="border-[#a0d0eb] my-3"></hr>
              <div className="grid gap-y-5 max-w-full overflow-hidden">
                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Order type
                  </span>
                  <label className="relative block w-full">
                    <select
                      value={order_type}
                      onChange={(e) =>
                        setFilter({ ...filter, order_type: e.target.value })
                      }
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    >
                      <option value="">All</option>
                      <option value={1}>Registered User</option>
                      <option value={2}>Guest</option>
                    </select>
                    <div className="select-arrow"></div>
                  </label>
                </div>
                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort by Status
                  </span>
                  <label className="relative block w-full">
                    <select
                      value={order_status}
                      onChange={(e) =>
                        setFilter({ ...filter, order_status: e.target.value })
                      }
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    >
                      <option value="">All</option>
                      {orderStatus.map((x, index) => {
                        return <option>{x}</option>;
                      })}
                    </select>
                    <div className="select-arrow"></div>
                  </label>
                </div>
                <div>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
                    <div className="w-full">
                      <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                        From
                      </span>
                      <label className="relative  z-50 w-full block cursor-pointer">
                        <DatePicker
                          className="border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none"
                          selected={start_date}
                          onChange={(date) =>
                            setFilter({ ...filter, start_date: date })
                          }
                          selectsEnd
                          startDate={start_date}
                          endDate={end_date}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                        />
                        <span className="absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5">
                          <Calendar className="text-gray-600 w-3 h-3" />
                        </span>
                      </label>
                    </div>
                    <div className="w-full">
                      <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                        To
                      </span>
                      <label className="relative block w-full cursor-pointer">
                        <DatePicker
                          className="border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none"
                          selected={end_date}
                          onChange={(date) =>
                            setFilter({ ...filter, end_date: date })
                          }
                          selectsEnd
                          startDate={start_date}
                          endDate={end_date}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                        />
                        <span className="absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5">
                          <Calendar className="text-gray-600 w-3 h-3" />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    limit
                  </span>
                  <label className="relative block w-full">
                    <select
                      value={limit}
                      onChange={(e) =>
                        setFilter({ ...filter, limit: e.target.value })
                      }
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                    </select>
                    <div className="select-arrow"></div>
                  </label>
                </div>
                {/* <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Verification Status
                  </span>
                  <div>
                    {
                      <>
                        <select
                          onChange={(e) => {
                            setFilter({ ...filter, v_status: e.target.value });
                          }}
                          name="verification_status"
                          className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                          value={v_status}
                        >
                          <option value=''>All</option>
                          {verification_status.map((elem, index) => {

                            return (
                              <>
                                <option key={index}>{elem}</option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    }
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TotalOrders;
